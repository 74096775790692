
<div>
  <div class="card">
    <div class="card-header">
      <span class="h5"><i class="fas fa-info-circle"></i> &nbsp; Riepilogo dei dati inseriti &nbsp;</span>
    </div>
    <div class="card-body">
      <div class="row justify-content-center">
        
        <div class="col-12">
          <h5 class="text-primary"><i class="fas fa-male"></i>&nbsp; <i class="fas fa-female"></i> &nbsp; Genitore / Tutor</h5>
          <p>
            <b>Grado di parentela:</b> {{model?.parent_info?.genitore_grado_parentela}}
          </p>
          <p>
            <b>Nome:</b> {{model?.parent_info?.genitore_cognome}} {{model?.parent_info?.genitore_nome}} {{model?.parent_info?.genitore_codice_fiscale}} 
          </p>
          <p>
            <b>Nato:</b> {{model?.parent_info?.genitore_sesso}}, {{model?.parent_info?.genitore_data_nascita | date:'dd-MM-yyyy' }} {{model?.parent_info?.genitore_citta_nascita}} ({{model?.parent_info?.genitore_provincia_nascita}}) 
          </p>
          <p>
            <b>Indirizzo:</b> {{model?.parent_info?.genitore_indirizzo}} - {{model?.parent_info?.genitore_cap}} - {{model?.parent_info?.genitore_citta}} ({{model?.parent_info?.genitore_provincia}})
          </p>
          <p>
            <b>Contatti:</b> {{model?.parent_info?.genitore_cellulare}} {{model?.parent_info?.genitore_email}}
          </p>
        </div>
      </div>
      <hr>

      <div class="row justify-content-center">
        <div class="col-12">
          <h5 class="text-primary"><i class="fas fa-home"></i>&nbsp; Ospitare Tutor</h5>
          <p>
            <b>Disponibilità ad ospitare Tutor:</b> {{model?.hospitality_info?.ospitare_trainee_tutor}} 
            <span *ngIf="model.hospitality_info.settimane_ospitare_trainee_tutor"><b>per:</b> {{model?.hospitality_info?.settimane_ospitare_trainee_tutor}}</span>
          </p>
        </div>
      </div>
      <hr>

      <div class="row justify-content-center">
        <div class="col-12" *ngFor="let child of model.children_info; let i = index">
          <h5 class="text-primary"><i class="fas fa-child"></i>&nbsp; Partecipante n.{{i+1}}</h5>
          <p>
            <b>Settimane di partecipazione</b>: {{child?.figlio_numero_settimane}}
          </p>
          <p>
            <b>Nome:</b> {{child?.figlio_cognome}} {{child?.figlio_nome}} {{child?.figlio_codice_fiscale}} 
          </p>
          <p>
            <b>Nato:</b> {{child?.figlio_sesso}}, {{child?.figlio_data_nascita | date:'dd-MM-yyyy' }} {{child?.figlio_citta_nascita}} ({{child?.figlio_provincia_nascita}})
          </p>
          <p>
            <b>Indirizzo:</b> {{child?.figlio_indirizzo}} - {{child?.figlio_cap}} - {{child?.figlio_citta}} ({{child?.figlio_provincia}})
          </p>
          <p>
            <b>Contatti:</b> {{child?.figlio_cellulare}} {{child?.figlio_email}}
          </p>
          <p>
            <b>Scuola:</b> {{child?.figlio_scuola_classe}} {{child?.figlio_scuola_tipo}}
          </p>
          <p *ngIf="child.figlio_note">
            <b>Note:</b> {{child?.figlio_note}}
          </p>
        </div>
      </div>
      <hr>

      <div class="row justify-content-center">
        <div class="col-12">
          <h5 class="text-primary"><i class="fas fa-file-invoice-dollar"></i>&nbsp; Fattura</h5>
          <div *ngIf="model.billing_info.fattura_tipo == 'persona_fisica'">
            <p>
              <b>Intestazione Fattura:</b> {{model?.billing_info?.fattura_persona_fisica_cognome}} {{model?.billing_info?.fattura_persona_fisica_nome}} {{model?.billing_info?.fattura_persona_fisica_codice_fiscale}} 
            </p>
            <p>
              <b>Indirizzo:</b> {{model?.billing_info?.fattura_persona_fisica_indirizzo}} - {{model?.billing_info?.fattura_persona_fisica_cap}} - {{model?.billing_info?.fattura_persona_fisica_citta}} ({{model?.billing_info?.fattura_persona_fisica_provincia}})
            </p>
          </div>
          <div *ngIf="model.billing_info.fattura_tipo == 'persona_giuridica'">
            <p>
              <b>Intestazione:</b> {{model?.billing_info?.fattura_persona_giuridica_ragione_sociale}} {{model?.billing_info?.fattura_persona_giuridica_codice_fiscale}}
            </p>
            <p>
              <b>Indirizzo:</b> {{model?.billing_info?.fattura_persona_giuridica_indirizzo}} - {{model?.billing_info?.fattura_persona_giuridica_cap}} - {{model?.billing_info?.fattura_persona_giuridica_citta}} ({{model?.billing_info?.fattura_persona_giuridica_provincia}})
            </p>
            <p>
              <span *ngIf="model.billing_info.fattura_persona_giuridica_sdi">
                <b>Codice Sdi:</b> {{model?.billing_info?.fattura_persona_giuridica_sdi}} 
              </span>
              <span *ngIf="model.billing_info.fattura_giuridica_pec">
                <b>PEC:</b> {{model?.billing_info?.fattura_giuridica_pec}}
              </span>
            </p>
          </div>
        </div>
      </div>
      <hr>

      <div class="row justify-content-center">
        <div class="col-12">
          <h5 class="text-primary"><i class="fas fa-file-invoice-dollar"></i>&nbsp; Privacy</h5>
          <div>
            <p>
              <span *ngIf="model.preference_info.autorizzation_newsletter">
                <b>Newsletter:</b> {{model?.preference_info?.consenso_newsletter}}
              </span>
            </p>
            <p *ngIf="model.preference_info.genitore_consenso_dati_personali == 'Si'">
              <b>Consenso al trattamento dei dati:</b> {{model?.preference_info?.genitore_consenso_dati_personali}} 
            </p>
            <p *ngIf="model.preference_info.genitore_consenso_immagine == 'Si'">
              <b>Consenso al trattamento delle immagini:</b> {{model?.preference_info?.genitore_consenso_immagine}} 
            </p>
          </div>
        </div>
      </div>
      <hr>

      <div class="row" *ngIf="enableAction">
        <div class="col-4">
          <button type="button" (click)="indietro()" [disabled]="CampService.ongoingRequest" class="btn btn-light btn-block">Modifica i dati inseriti</button>
        </div>
        <div class="col-8">
          <button type="button" style="text-align:center" [class.spinner]="CampService.ongoingRequest" [disabled]="CampService.ongoingRequest" (click)="iscrivi()" class="btn btn-primary btn-block">Completa l'iscrizione</button>
        </div>
      </div>

    </div>
  </div>
</div>
