<header class="masthead text-center text-white">
  <div class="masthead-content">
    <div class="container">
      <h1 class="masthead-heading mb-0">{{currentCamp.venue_name}}</h1>
      <!-- <h2 class="masthead-subheading mb-0">Iscrizioni OnLine per Docenti</h2> -->
      <!-- <a href="#" class="btn btn-primary btn-xl rounded-pill mt-5">Learn More</a> -->
    </div>
  </div>
  <div class="bg-circle-3 bg-circle"></div>
  <div class="bg-circle-2 bg-circle"></div>
  <div class="bg-circle-1 bg-circle"></div>
  <div class="bg-circle-4 bg-circle"></div>
  <!-- <div [ngStyle]="{ 'background-image': 'url(https://picsum.photos/1280/600)'}" class="bg-cover"></div> -->
  <div [ngStyle]="{ 'background-image': 'url(assets/img/camp-educoitalia.jpg)'}" class="bg-cover"></div>
</header>


<div class="container">

  <!-- LODING -->
  <div class="row" [hidden]="!getCampsService.isOngoingRequest">
    <div class="col-md-12 text-center">
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <!-- SEZIONE DEDICATA ALLA RICERCA -->
  <!-- Non cancellare perché serve per riempire gli array nell'AppState, altrimenti quando si va su questa pagina senza passare dalla home, non si vedono i dati -->
  <app-camp-search-filters [hidden]="true" id="ricercaCamp" (campChanged)="campChangedEvent()">
  </app-camp-search-filters>


  <h1 class="text-center mt-3">Modulo Iscrizione</h1>
  <h3 class="text-center">{{currentCamp.venue_name}}</h3>
  <h5 class="text-center">Dal <b>{{currentCamp.start | date}}</b> al
    <b>{{currentCamp.end | date}}</b>, dalle {{currentCamp.start_time}} alle
    {{currentCamp.end_time}}<br>presso <b class="text-uppercase">{{currentCamp.venue_denomination}}</b> sito
    in
    {{currentCamp.route}} {{currentCamp.street_number}},
    <b class="text-uppercase">{{currentCamp.administrative_area_level_3_long_version}}
      ({{currentCamp.administrative_area_level_2_short_version}})</b></h5>


  <!-- CONTENITORE DEL FORM DI INSERIMENTO -->
  <div class="row p-2" *ngIf="!summaryMode">
    <div class="col-md-6">
      <div class="card mt-2">
        <div class="card-header">
          {{currentCamp.locality}} ({{currentCamp.administrative_area_level_2_short_version}},
          {{currentCamp.administrative_area_level_1_long_version}})
        </div>
        <div class="card-body">
          <h5 class="card-title text-uppercase">{{currentCamp.venue_name}}</h5>
          <p class="card-text">
            <i class="far fa-calendar-alt"></i> dal {{currentCamp.start | date}} al {{currentCamp.end | date}}
          </p>
          <p class="card-text">
            <i class="fas fa-map-marked-alt"></i> {{currentCamp.route}}, {{currentCamp.street_number}}<br>
            <small>{{currentCamp.locality}} ({{currentCamp.administrative_area_level_2_short_version}},
              {{currentCamp.administrative_area_level_1_long_version}})</small><br>
            <small>{{currentCamp.postal_code}} {{currentCamp.country_long_version}}</small>
          </p>
          <p class="card-text">
            <i class="fas fa-graduation-cap"></i>&nbsp;<span
              *ngFor="let a of currentCamp.audience_name; let last = last">{{a}}<span
                *ngIf="!last">,&nbsp;</span></span>
          </p>
          <p class="card-text">
            <i class="fas fa-fingerprint"></i>&nbsp;{{currentCamp.camp_code}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6">

      <!-- SEZIONE DEDICATA AL FORM DI ISCRIZIONE -->
      <div class="card mt-2">
        <div class="card-header">
          Quote di iscrizione e partecipazione
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <p *ngIf="currentCamp.online_description" class="line-breaker">
                {{currentCamp.online_description}}
              </p>
              <!-- <div class="row" *ngIf="currentCamp.quote_enrollment_fee > 0">
                <div class="col">
                  <p>La quota della settimana include la quota di iscrizione di {{currentCamp.quote_enrollment_fee}} €</p>
                </div>
              </div> -->
              <div class="row">
                <div class="col">
                  Quota 1 settimana<br>
                  <h4>{{currentCamp.quote_course_fee_w1}} €</h4>
                </div>
                <div *ngIf="currentCamp.quote_course_fee_w2" class="col">
                  Quota 2 settimane<br>
                  <h4>{{currentCamp.quote_course_fee_w2}} €</h4>
                </div>
                <div *ngIf="currentCamp.quote_course_fee_w3" class="col">
                  Quota 3 settimane<br>
                  <h4>{{currentCamp.quote_course_fee_w3}} €</h4>
                </div>
                <div *ngIf="currentCamp.quote_course_fee_w4" class="col">
                  Quota 4 settimane<br>
                  <h4>{{currentCamp.quote_course_fee_w4}} €</h4>
                </div>
                <div *ngIf="currentCamp.quote_course_fee_w5" class="col">
                  Quota 5 settimane<br>
                  <h4>{{currentCamp.quote_course_fee_w5}} €</h4>
                </div>
              </div>
              <div class="row justify-content-md-center">
                <div *ngIf="currentCamp.leaflet_url" class="col-md-5">
                  <a href="{{currentCamp.leaflet_url}}" target="_blank" class="btn btn-success">Scarica il
                    volantino</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h3 *ngIf="!summaryMode" class="text-center mt-3">Passo {{sectionNumber}} di 5</h3>

  <progressbar *ngIf="!summaryMode" [value]="progressBar()"></progressbar>


  <div class="row p-2 mt-3" *ngIf="!summaryMode">

    <div class="col-md-12">

      <tabset [justified]="true" type="pills" #staticTabs>

        <tab heading="Genitore o Tutor" [disabled]="true">

          <!-- SEZIONE DATI GENITORE -->
          <div class="card mt-3" *ngIf="sectionNumber > 0">
            <div class="card-header">
              <h5>Inserire i dati del GENITORE/TUTORE</h5>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <app-camp-booking-form-parent [completeModel]="completeModel.parent_info"
                    (nextPressed)="continue($event)" [section]="1" [currentSection]="sectionNumber">
                  </app-camp-booking-form-parent>
                </div>
              </div>
            </div>
          </div>
        </tab>

        <tab heading="Ospitalità" [disabled]="true">

          <!-- SEZIONE DATI OSPITALITA TUTOR -->
          <div class="card mt-3" *ngIf="sectionNumber > 1">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <app-camp-booking-form-hospitality [completeModel]="completeModel.hospitality_info"
                    (nextPressed)="continue($event)" (backPressed)="backward()" [camp]="currentCamp" [section]="2"
                    [currentSection]="sectionNumber"></app-camp-booking-form-hospitality>
                </div>
              </div>
            </div>
          </div>
        </tab>

        <tab heading="Partecipante" [disabled]="true">

          <div class="card mt-3" *ngIf="sectionNumber > 2">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="form-group form-inline">
                    <label *ngIf="canEditSubscriber">Quanti partecipanti vuoi iscrivere? <span>*</span></label>
                    <label *ngIf="!canEditSubscriber"><b>Hai scelto di iscrivere {{numOfSubscribers}}
                        partecipanti</b></label>
                    <select [(ngModel)]="numOfSubscribers" name=numero_partecipanti *ngIf="canEditSubscriber"
                      #numero_partecipanti="ngModel" class="form-control col-md-6 col-12 ml-md-3"
                      id="numero_partecipantiInput" (change)="changeNumOfSubscriber()">
                      <option></option>
                      <option *ngFor="let num of max_num_of_subscriber" [value]="num">{{num}}</option>
                    </select>
                    <button class="btn btn-danger col-md-6 col-12 ml-md-3" *ngIf="!canEditSubscriber"
                      (click)="editSubscriberNumber()">Cambia numero di iscritti</button>
                  </div>
                  <span class="text-muted" *ngIf="!canEditSubscriber">Ricorda che diminuendo il numero di
                    partecipanti,
                    verrano cancellate le ultime schede inserite</span>
                </div>
              </div>

              <!-- SEZIONE DATI DI SELEZIONE ISCRIZIONE BAMBINI -->
              <div *ngIf="sectionNumber > 2 && subscriberForms.length > 0" class="mt-4">
                <div *ngFor="let subscriber of subscriberForms; let i = index">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-12">
                          <i class="fas fa-child"></i>&nbsp; Dati anagrafici partecipante n.{{i+1}} &nbsp;
                          <button class="btn btn-danger btn-sm float-md-right" (click)="removeSubscriber(i)">Rimuovi
                            Scheda d'iscrizione</button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <app-camp-booking-form-child [completeModel]="completeModel.children_info" #childSubscribers
                          [parentInfo]="completeModel.parent_info" [camp]="currentCamp" [formNumber]="i"></app-camp-booking-form-child>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
              <div class="form-group row" *ngIf="subscriberForms.length > 0 && sectionNumber == 3">
                <div class="col-4">
                  <button type="button" (click)="backward()" class="btn btn-light btn-block"><i
                      class="fas fa-arrow-left"></i>&nbsp; Indietro</button>
                </div>
                <div class="col-8">
                  <button type="button" (click)="checkSubcribers()" class="btn btn-primary btn-block">Avanti</button>
                </div>
              </div>
            </div>
          </div>
        </tab>

        <tab heading="Dati fatturazione" [disabled]="true">

          <!-- SEZIONE DEDICATA AI DATI DI FATTURAZIONE -->
          <div class="card mt-3" *ngIf="sectionNumber > 3">
            <div class="card-header">
              <span class="h5"><i class="fas fa-file-invoice-dollar"></i> &nbsp; INFORMAZIONI DI FATTURAZIONE
                &nbsp;</span>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <app-camp-booking-form-billing (nextPressed)="continue($event)" (backPressed)="backward()"
                    [completeModel]="completeModel.billing_info" [parentInfo]="completeModel.parent_info"
                    [childrenInfo]="completeModel.children_info" [section]="4" [currentSection]="sectionNumber">
                  </app-camp-booking-form-billing>
                </div>
              </div>
            </div>
          </div>
        </tab>

        <tab heading="Privacy" [disabled]="true">

          <!-- SEZIONE DEDICATA AIPERMESSI E NEWSLETTER -->
          <div class="card mt-3" *ngIf="sectionNumber > 4">
            <div class="card-header">
              <span class="h5"><i class="fas fa-file-contract"></i> &nbsp; PRIVACY &nbsp;</span>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <app-camp-booking-form-preferences [completeModel]="completeModel.preference_info"
                    (nextPressed)="continue($event)" (backPressed)="backward()" [section]="5"
                    [currentSection]="sectionNumber"></app-camp-booking-form-preferences>
                </div>
              </div>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
  </div>

  <!-- CONTENITORE DEL RIEPILOGO DEL FORM DI INSERIMENTO -->
  <div class="row p-2 mt-5" *ngIf="summaryMode">
    <div class="col-md-12">
      <app-camp-booking-form-summary [model]="completeModel" (submitPressed)="submit()"
        (backPressed)="returnToStart()"></app-camp-booking-form-summary>
    </div>
  </div>

</div>
