import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// localizzation
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeIt, 'it')

// services
import { BackendService } from './_services/backend.service';
import { CampsService } from './_services/camps.service';
import { AppStateService } from './_services/appstate.service';

// plugin
import { SimpleNotificationsModule } from 'angular2-notifications';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AlertModule } from 'ngx-bootstrap/alert';

// components
import { CampsComponent } from './camps/camps.component';
import { CampBookingComponent } from './camp-booking/camp-booking.component';
import { CampBookingConfirmationComponent } from './camp-booking-confirmation/camp-booking-confirmation.component';
import { CampSearchFiltersComponent } from './camp-search-filters/camp-search-filters.component';

import { CampBookingFormParentComponent } from './camp-booking-form-parent/camp-booking-form-parent.component';
import { CampBookingFormChildComponent } from './camp-booking-form-child/camp-booking-form-child.component';
import { CampBookingFormHospitalityComponent } from './camp-booking-form-hospitality/camp-booking-form-hospitality.component';
import { CampBookingFormBillingComponent } from './camp-booking-form-billing/camp-booking-form-billing.component';
import { CampBookingFormPreferencesComponent } from './camp-booking-form-preferences/camp-booking-form-preferences.component';
import { CampBookingFormSummaryComponent } from './camp-booking-form-summary/camp-booking-form-summary.component';

import { NotFoundComponent } from './not-found/not-found.component';

import * as Sentry from "@sentry/browser";


// Sentry.init({
//   dsn: "https://830f20949ced432f985797b6b74d26dd@sentry.io/1838538",
// });

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    console.error(error);
    Sentry.showReportDialog({ eventId });
  }
}

@NgModule({
   declarations: [
      AppComponent,
      CampsComponent,
      CampBookingComponent,
      CampBookingConfirmationComponent,
      CampSearchFiltersComponent,
      CampBookingFormParentComponent,
      CampBookingFormHospitalityComponent,
      CampBookingFormChildComponent,
      CampBookingFormBillingComponent,
      CampBookingFormPreferencesComponent,
      CampBookingFormSummaryComponent,
      NotFoundComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      SimpleNotificationsModule.forRoot(),
      TypeaheadModule.forRoot(),
      TabsModule.forRoot(),
      ProgressbarModule.forRoot(),
      AlertModule.forRoot()
   ],
   providers: [
      {provide: LOCALE_ID, useValue: "it" },
      {provide: ErrorHandler, useClass: SentryErrorHandler },
      BackendService,
      CampsService,
      AppStateService,
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule {
}
