<header class="masthead text-center text-white">
  <div class="masthead-content">
    <div class="container">
      <h1 class="masthead-heading mb-0 text-uppercase" style="line-height: 1;padding: 1.5rem;">Educo English
        Camp<br><small style="font-size: 50%;">Per bambini e ragazzi di ogni età che vogliono imparare l’inglese
          divertendosi.</small></h1>
      <a [routerLink]="['/']" fragment="ricercaCamp" class="btn btn-primary btn-xl rounded-pill mt-5">Cerca un English
        Camp</a>
    </div>
  </div>
  <div class="bg-circle-1 bg-circle"></div>
  <div class="bg-circle-2 bg-circle"></div>
  <div class="bg-circle-3 bg-circle"></div>
  <div class="bg-circle-4 bg-circle"></div>
  <div [ngStyle]="{ 'background-image': 'url(assets/img/camp-educoitalia.jpg)'}" class="bg-cover"></div>
</header>

<div class="container">

  <!-- LODING -->
  <div class="row mt-5" [hidden]="!getCampService.isOngoingRequest">
    <div class="col-md-12 text-center">
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <!-- SEZIONE DEDICATA ALLA RICERCA -->
  <app-camp-search-filters id="ricercaCamp" (campChanged)="campChangedEvent()"></app-camp-search-filters>

  <!-- SEZIONE DEDICATA AI CORSI -->
  <div class="row mt-5" *ngIf="!getCampService.isOngoingRequest && filtered.length > 0">
    <div class="col-md-12">
      <h1 class="text-center">Campi</h1>
      <!-- <h5 class="text-center">Filtri applicati: Regione/Provincia + Mese</h5> -->
      <div class="card-columns">
        <div class="card mt-3" *ngFor="let c of filtered">
          <div class="card-header">
            {{c.locality}} ({{c.administrative_area_level_2_short_version}},
            {{c.administrative_area_level_1_long_version}})
          </div>
          <div class="card-body">
            <h5 class="card-title text-uppercase">{{c.venue_name}}</h5>
            <p class="card-text">
              <i class="far fa-calendar-alt"></i> dal {{c.start | date}} al {{c.end | date}}
            </p>
            <p class="card-text">
              <i class="fas fa-map-marked-alt"></i> {{c.route}}, {{c.street_number}}<br>
              <small>{{c.locality}} ({{c.administrative_area_level_2_short_version}},
                {{c.administrative_area_level_1_long_version}})</small><br>
              <small>{{c.postal_code}} {{c.country_long_version}}</small>
            </p>
            <p class="card-text text-danger" *ngIf="c.type == 2">
              <i class="fas fa-bed"></i>&nbsp;Summer Camp - include pernottamento
            </p>
            <p class="card-text">
              <i class="fas fa-graduation-cap"></i>&nbsp;<span
                *ngFor="let a of c.audience_name; let last = last">{{a}}<span *ngIf="!last">,&nbsp;</span></span>
            </p>
            <p class="card-text">
              <i class="fas fa-fingerprint"></i>&nbsp;{{c.camp_code}}
            </p>
            <a *ngIf="c.id && c.buyable == 1; else contattaciButton" [routerLink]="'/prenota/'+c.id" href="#"
              class="btn btn-primary btn-block">Iscriviti al
              Camp&nbsp;{{c.camp_code}}</a>
            <ng-template #contattaciButton>
              <p class="small text-center">Iscrizione non ancora disponibile, contattaci per informazioni e dettagli.</p>
              <a href="https://www.educoitalia.it/contatti/" target="_blank"
                class="btn btn-secondary btn-block">Contattaci</a>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
