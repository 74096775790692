import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CampsService } from '../_services/camps.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-camp-booking-form-preferences',
  templateUrl: './camp-booking-form-preferences.component.html',
  styleUrls: ['./camp-booking-form-preferences.component.css']
})
export class CampBookingFormPreferencesComponent implements OnInit {

  @ViewChild("form", { static: false }) form;

  @Input() currentSection: number;
  @Input() section: number = 0;
  @Output() nextPressed:EventEmitter<any> = new EventEmitter<any>();
  @Output() backPressed = new EventEmitter();

  public newsletterPreference = [
    { name: 'Corsi di Formazione in Italia', preference: false },
    { name: 'Corsi di Formazione All’estero', preference: false },
    { name: 'Corsi di Formazione CLIL', preference: false },
    { name: 'Convegni', preference: false },
    { name: 'Teatrino', preference: false },
    { name: 'Educo Campus', preference: false },
    { name: 'Pop Music\'n Culture', preference: false },
    { name: 'Storytelling', preference: false },
    { name: 'Educo Camps', preference: false },
    { name: 'Educo Summer Camps', preference: false }
  ];

  @Input() completeModel: any;
  public model:any;
  
  constructor(private campService: CampsService, private notify: NotificationsService) { }

  ngOnInit() {

    this.init();
  }

  init() 
  {
    if (this.completeModel != null && typeof this.completeModel != "undefined")
      this.model = this.completeModel;
    else
    if (this.model == null || typeof this.model == "undefined")
    {
        this.model = {};
        this.model.autorizzation_newsletter = false;
      // this.model.autorizzation_image = true;
    } 

    this.prepareNewsletter ();
  }


  prepareNewsletter ()
  {
    if (typeof this.model.consenso_newsletter != "undefined")
    {
      let pref_array = this.model.consenso_newsletter.split(',');
      pref_array.forEach(pref => {
        this.newsletterPreference.forEach(element => {
          if (pref.indexOf(element.name) >= 0)
            element.preference = true;
        });
      });
    }
    
  }


  public get isFormValid() {
    return this.form.form.valid;
  }

  submit() {

    if (this.isFormValid == false) {
      this.notify.error("Mancano alcuni dati per completare l'iscrizione");
      return;
    }

    //  setto il model per le preferenze di newsletter
    this.model.consenso_newsletter = " ";
    this.newsletterPreference.forEach(pref => {
      if (pref.preference == true)
        this.model.consenso_newsletter += pref.name + ",";
    });
    this.model.consenso_newsletter = this.model.consenso_newsletter.substring(0, this.model.consenso_newsletter.length - 1);
    this.model.consenso_newsletter = this.model.consenso_newsletter.trim();

    if (this.model.autorizzation == true)
      this.model.genitore_consenso_dati_personali = "Si";
    else
      this.model.genitore_consenso_dati_personali = "No";

    if (this.model.autorizzation_image == true)
      this.model.genitore_consenso_immagine = "Si";
    else
      this.model.genitore_consenso_immagine = "No";

    this.nextPressed.emit( {type: 'preferences', model: this.model} );
  }

  indietro ()
  {
    this.backPressed.emit();
  }

  autorizzationNewsletterOnChange(event) {
    if (this.model.autorizzation_newsletter) {
      this.newsletterPreference.forEach(element => {
        element.preference = true;
      });
    } else {
      this.newsletterPreference.forEach(element => {
        element.preference = false;
      });
    }
  }

  autorizzationSingolNewsletterOnChange(event) {
    for (var i = 0; i < this.newsletterPreference.length; i++) {
      if (this.newsletterPreference[i].preference == true) {
        if (this.model.autorizzation_newsletter == false)
          this.model.autorizzation_newsletter = true;
        return;
      }
    }

    if (this.model.autorizzation_newsletter == true)
      this.model.autorizzation_newsletter = false;
  }



  public get getCampsService() {
    return this.campService;
  }

}
