import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Province } from '../_utils/province';
import { format } from 'date-fns';
import CodiceFiscale from 'codice-fiscale-js';
import { NotificationsService } from 'angular2-notifications';
import { CampsService } from '../_services/camps.service';

@Component({
  selector: 'app-camp-booking-form-child',
  templateUrl: './camp-booking-form-child.component.html',
  styleUrls: ['./camp-booking-form-child.component.css']
})
export class CampBookingFormChildComponent implements OnInit {

  @ViewChild("form", { static: false }) form;

  @Input() currentSection: number;
  @Input() formNumber: number = 0;
  @Input() section: number = 0;
  @Input() camp: any;
  @Output() nextPressed = new EventEmitter();
  @Output() backPressed = new EventEmitter();

  public prov_array = Province;
  public weekSelected:boolean = false;

  public schoolType = [
    { name: 'Infanzia', index: 0, classes: [1] },
    { name: 'Primaria', index: 1, classes: [1,2,3,4,5] },
    { name: 'Secondaria di I grado', index: 2, classes: [1,2,3] },
    { name: 'Secondaria di II grado', index: 3, classes: [1,2,3,4,5] },
  ]
  public weeks: any[] = [
    { value: false, locked: false },
    { value: false, locked: false },
    { value: false, locked: false },
    { value: false, locked: false },
  ];

  @Input() completeModel: any;
  @Input() parentInfo: any;
  public model: any;

  constructor(private campService: CampsService, private notify: NotificationsService) { }

  ngOnInit() {

    this.init();
  }

  init()
  {
    //  se viene passato il model lo assegno
    if (this.completeModel != null && typeof this.completeModel != "undefined")
    {
      this.model = this.completeModel[this.formNumber];
    }
    //  se il model continua ad essere vuoto allora lo inizializzo
    if (this.model == null || typeof this.model == "undefined")
    {
        this.model = {};
        this.model.figlio_sesso = "Maschio";
    }
    //  se non è vuoto allora vuol dire che ho già selezionato le date
    else
    {
      this.weekSelected = true;
    }

    //  se ci sono le info del genitore precompilo alcuni campi
    if (this.parentInfo != null && typeof this.parentInfo != "undefined")
    {
      if (typeof this.model.figlio_indirizzo == "undefined")
        this.model.figlio_indirizzo = this.parentInfo.genitore_indirizzo;
      if (typeof this.model.figlio_citta == "undefined")
        this.model.figlio_citta = this.parentInfo.genitore_citta;
      if (typeof this.model.figlio_cap == "undefined")
        this.model.figlio_cap = this.parentInfo.genitore_cap;
      if (typeof this.model.figlio_provincia == "undefined")
        this.model.figlio_provincia = this.parentInfo.genitore_provincia
      if (typeof this.model.figlio_email == "undefined")
        this.model.figlio_email = this.parentInfo.genitore_email;
      if (typeof this.model.confirm_email == "undefined")
        this.model.confirm_email = this.parentInfo.confirm_email;
    }

    this.prepareWeeks();
  }

  prepareWeeks()
  {
    JSON.parse(this.camp.lock_week).forEach(element => {
      this.weeks[element-1].locked = true;
      this.weeks[element-1].value = true;
    });

    if (this.camp.not_available_week == null)
      this.camp.not_available_week = "[]";

    JSON.parse(this.camp.not_available_week).forEach(element => {
      this.weeks[element-1].locked = true;
      this.weeks[element-1].value = false;
    });

    if (typeof this.model.figlio_numero_settimane != "undefined")
    {
      let week_array = this.model.figlio_numero_settimane.split(',');
      week_array.forEach(week => {
        if (week.indexOf('prima') >= 0)
          this.weeks[0].value = true;
        if (week.indexOf('seconda') >= 0)
          this.weeks[1].value = true;
        if (week.indexOf('terza') >= 0)
          this.weeks[2].value = true;
        if (week.indexOf('quarta') >= 0)
          this.weeks[3].value = true;
      });
    }

    //  controllo che ci siano settimane settate
    if (this.weekValid())
      this.weekSelected = true;
  }

  //  Validazione della selezione delle settimane
  weekValid ()
  {
    var check = false;
    this.weeks.forEach(element => {
      if (element.value == true)
        check = true;
    });
    return check;
  }

  onWeekSelect ()
  {
    this.weekSelected = true;
  }

  get fiscalCodeValid() {
    if (this.model.figlio_codice_fiscale != null && typeof this.model.figlio_codice_fiscale != "undefined")
      return CodiceFiscale.check(this.model.figlio_codice_fiscale);
    return false;
  }

  /**
   * Verifica e trasforma il testo nel campo dedicato al codice fiscale
   */
  fiscalCodeOnChange() {
    if (this.model.figlio_codice_fiscale != null && typeof this.model.figlio_codice_fiscale != "undefined") {
      this.model.figlio_codice_fiscale = this.model.figlio_codice_fiscale.toUpperCase();
      if (CodiceFiscale.check(this.model.figlio_codice_fiscale)) {
        const cf = new CodiceFiscale(this.model.figlio_codice_fiscale)
        let fiscal_code = cf.toJSON();

        this.model.figlio_citta_nascita = fiscal_code.birthplace;
        if (fiscal_code.gender == 'M')
          this.model.figlio_sesso = "Maschio";
        else
          this.model.figlio_sesso = "Femmina";
        this.model.figlio_data_nascita = format(new Date(cf.year, cf.month - 1, cf.day), 'yyyy-MM-dd');

        let provincia = this.prov_array.find(x => x.sigla_automobilistica == fiscal_code.birthplaceProvincia);
        if (provincia)
          this.model.figlio_provincia_nascita = provincia.nome;

      }
    }
  }

  /**
   * Trasforma il testo nel campo dedicato alla mail e alla sua verifica
   */
  emailOnChange() {
    if (this.model.figlio_email != null && typeof this.model.figlio_email != "undefined") {
      this.model.figlio_email = this.model.figlio_email.toLowerCase();
    }
  }
  confirmEmailOnChange() {
    if (this.model.confirm_email != null && typeof this.model.confirm_email != "undefined") {
      this.model.confirm_email = this.model.confirm_email.toLowerCase();
    }
  }


  public get isFormValid() {
    return (
      this.form.form.valid &&
      this.model.figlio_email == this.model.confirm_email &&
      (this.weeks[0].value || this.weeks[1].value || this.weeks[2].value || this.weeks[3].value)
    )
  }

  public forceSubmit() {
    this.form.submitted = true;
    this.form.ngSubmit.emit();
  }

  submit() {

    if (this.isFormValid == false) {
      this.notify.error("Mancano alcuni dati per completare l'iscrizione");
      return;
    }

    //  costruisco le settimane
    this.buildWeeks();

    if (this.model.figlio_numero_settimane == null) {
      this.notify.error("Non è stata selezionata nessuna settimana");
      return;
    }

    this.nextPressed.emit();
  }

  indietro ()
  {
    this.backPressed.emit();
  }

  //  Registra un evento di cambiamento della classe di appartenenza
  onSchoolChange () {
    this.model.figlio_scuola_classe = 1;
  }

  get currentClassType () {

    for(var i=0; i < this.schoolType.length; i++)
      if (this.schoolType[i].name === this.model.figlio_scuola_tipo)
        return this.schoolType[i].classes;

    return this.schoolType[0].classes;
  }

  //  Registra il cambiamento della classe dalle radio button
  changeRadioClass (event) {
    this.model.figlio_scuola_classe = event;
  }


  buildWeeks ()
  {
    this.model.figlio_numero_settimane = "";

    if (this.weeks[0].value == true)
      this.model.figlio_numero_settimane += ",prima settimana"
    if (this.weeks[1].value == true)
      this.model.figlio_numero_settimane += ",seconda settimana"
    if (this.weeks[2].value == true)
      this.model.figlio_numero_settimane += ",terza settimana"
    if (this.weeks[3].value == true)
      this.model.figlio_numero_settimane += ",quarta settimana"

    if (this.model.figlio_numero_settimane.length > 0)
      this.model.figlio_numero_settimane = this.model.figlio_numero_settimane.substring(1);
    else
    {
      this.weeks[0].value == false;
      this.weeks[1].value == false;
      this.weeks[2].value == false;
      this.weeks[3].value == false;
    }
  }


  public get getCampsService() {
    return this.campService;
  }

}
