import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  public searchModel: any = {};
  public filtered: any[] = [];
  public nextDays: any[] = [];
  public showDetails: boolean = false;

  constructor() { }

  public resetSearchModel()
  {
    this.searchModel = {};
    this.filtered = [];
    this.nextDays = [];
    this.showDetails = false;
  }


}
