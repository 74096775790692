import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BackendService } from "./backend.service";
import { Subject } from "rxjs";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  private ongoingRequest = false;
  private _subject = new Subject<boolean>();

  constructor(private http: HttpClient, private backend: BackendService) {
  }

  private startRequest() {
    this.ongoingRequest = true;
    this._subject.next(this.ongoingRequest);
  }

  private finishRequest() {
    this.ongoingRequest = false;
    this._subject.next(this.ongoingRequest);
  }

  public getIp(callbackSuccess: any = null, callbackError: any = null) {

    this.http.get(environment.ipServiceUrl, { responseType: 'json', observe: 'body' }).subscribe(
      (response) => {

        let resJson = response;

        this.finishRequest();

        if (callbackSuccess != null) {
          callbackSuccess(resJson);
        }
      },
      (error) => {

        this.backend.showErrors(error, 'GET');

        this.finishRequest();

        if (callbackError != null) {
          callbackError(error);
        }
      }
    );
  }
}
