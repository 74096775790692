import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  headers: HttpHeaders;
  options: any;

  constructor(private http: HttpClient, private notificationsService: NotificationsService) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.options = { headers: this.headers, responseType: 'json', observe: 'body' };
  }



  get(resource: string, searchParams: HttpParams) {

    if (searchParams != null)
      this.options.search = searchParams;

    return this.http.get(environment.apiUrl + resource, this.options);
  }

  post(resource: string, body: any[], searchParams: HttpParams = null) {

    if (searchParams != null)
      this.options.search = searchParams;

    return this.http.post(environment.apiUrl + resource, JSON.stringify(body), this.options);
  }

  put(resource: string, body: any[], searchParams: HttpParams = null) {

    if (searchParams != null) {
      this.options.search = searchParams;
    }

    return this.http.put(environment.apiUrl + resource, JSON.stringify(body), this.options);
  }

  showErrors(error: any, httpMethod: string) {

    console.error(error);

    // controllo se � un errore di validazione
    if (error.status == 422) {

      var errorJson = error.json();

      for (var key in errorJson) {
        var value = errorJson[key];
        this.notificationsService.error(key.toUpperCase(), value);
      }
    }
    else if (error.status == 404) {
      this.notificationsService.warn("Resource not found", "what you were looking for was not found");
    }
    else if (error.status == 400) {

      var errorJson = error.json();

      this.notificationsService.error(errorJson.error_description);
    }
    else {

      this.notificationsService.error("Error " + error.status + " " + error.statusText, "backend error, check the console for debug information");
    }

    this.notifyError(error, httpMethod);
  }


  public notifyError(error: any, httpMethod: string) {

    let model: any = {

      application: 'Camp Application Form',
      error: error,
      http_method: httpMethod
    }

    //  this.post('errors/notify', model).subscribe(
    //    	(response) => {},
    //    	(error) => {
    // 		console.error(error);
    //    	}
    //   );

  }

}




