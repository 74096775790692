import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CampsComponent } from './camps/camps.component';
import { CampBookingComponent } from './camp-booking/camp-booking.component';
import { CampBookingConfirmationComponent } from './camp-booking-confirmation/camp-booking-confirmation.component';
import { NotFoundComponent } from './not-found/not-found.component';


const routes: Routes = [
  {path: '', component: CampsComponent},
  {path: 'prenota/:id', component: CampBookingComponent},
  {path: 'prenota/:id/confirmation', component: CampBookingConfirmationComponent},
  
  {path: 'notfound', component: NotFoundComponent},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
