import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Province } from '../_utils/province';
import CodiceFiscale from 'codice-fiscale-js';
import { CampsService } from '../_services/camps.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-camp-booking-form-billing',
  templateUrl: './camp-booking-form-billing.component.html',
  styleUrls: ['./camp-booking-form-billing.component.css']
})
export class CampBookingFormBillingComponent implements OnInit {

  @ViewChild("form", { static: false }) form;

  @Input() currentSection: number;
  @Input() section: number = 0;
  @Input() parentInfo: any = {};
  @Input() childrenInfo: any = [];
  @Output() nextPressed: EventEmitter<any> = new EventEmitter<any>();
  @Output() backPressed = new EventEmitter();

  public prov_array = Province;

  @Input() completeModel: any;
  public model:any;

  constructor(private campService: CampsService, private notify: NotificationsService) { }

  ngOnInit() {

    this.init();
  }

  init()
  {
    if (this.completeModel != null && typeof this.completeModel != "undefined")
      this.model = this.completeModel;
    else
    if (this.model == null || typeof this.model == "undefined")
    {
        this.model = {};
    } 
  }

  get fiscalCodeValid() {
      return CodiceFiscale.check(this.model.fattura_persona_fisica_codice_fiscale);
  }

  public get isFormValid() {
    return (this.form.form.valid);
  }

  submit() 
  {
    //  preparo il modello
    this.prepareModel();

    if (this.isFormValid == false) {
      this.notify.error("Mancano alcuni dati per completare l'iscrizione");
      return;
    }

    this.nextPressed.emit( {type: 'billing', model: this.model} );
  }

  indietro ()
  {
    this.backPressed.emit();
  }

  prepareModel() {

    if (this.model.intestazione_fattura != null && typeof this.model.intestazione_fattura != "undefined")
    {
      if (this.model.intestazione_fattura.trim().length > 0)
      {
        // console.log(this.model.intestazione_fattura);
        
        switch (this.model.intestazione_fattura) {
        
          case 'genitore': 
            this.prepareModelForParent();
            break;
          case 'altro':
            if (this.model.fattura_tipo == "persona_fisica")
              this.prepareModelForPersonaFisica();
            if (this.model.fattura_tipo == "persona_giuridica")
              this.prepareModelForPersonaGiuridica();
            break;
          default:
            this.childrenInfo.forEach(child => {
              let string = child.figlio_cognome+'-'+child.figlio_nome+'-'+child.figlio_data_nascita;
              if (this.model.intestazione_fattura == string)
                this.prepareModelForChild(child);
            });
            break;
        } 
      }
    }
  }

  prepareModelForParent ()
  {
    this.deleteModelPersonaGiuridica();
    this.model.fattura_tipo = "persona_fisica";
    this.model.fattura_persona_fisica_cognome = this.parentInfo.genitore_cognome;
    this.model.fattura_persona_fisica_nome = this.parentInfo.genitore_nome;
    this.model.fattura_persona_fisica_codice_fiscale = this.parentInfo.genitore_codice_fiscale;
    this.model.fattura_persona_fisica_indirizzo = this.parentInfo.genitore_indirizzo;
    this.model.fattura_persona_fisica_citta = this.parentInfo.genitore_citta;
    this.model.fattura_persona_fisica_cap = this.parentInfo.genitore_cap;
    this.model.fattura_persona_fisica_provincia = this.parentInfo.genitore_provincia;
  }

  prepareModelForChild(child: any)
  {
    this.deleteModelPersonaGiuridica();
    this.model.fattura_tipo = "persona_fisica";
    this.model.fattura_persona_fisica_cognome = child.figlio_cognome;
    this.model.fattura_persona_fisica_nome = child.figlio_nome;
    this.model.fattura_persona_fisica_codice_fiscale = child.figlio_codice_fiscale;
    this.model.fattura_persona_fisica_indirizzo = child.figlio_indirizzo;
    this.model.fattura_persona_fisica_citta = child.figlio_citta;
    this.model.fattura_persona_fisica_cap = child.figlio_cap;
    this.model.fattura_persona_fisica_provincia = child.figlio_provincia;
  }

  prepareModelForPersonaFisica ()
  {
    this.deleteModelPersonaGiuridica();
  }

  deleteModelPersonaFisica ()
  {
    delete this.model.fattura_persona_fisica_cognome;
    delete this.model.fattura_persona_fisica_nome;
    delete this.model.fattura_persona_fisica_codice_fiscale;
    delete this.model.fattura_persona_fisica_indirizzo;
    delete this.model.fattura_persona_fisica_citta;
    delete this.model.fattura_persona_fisica_cap;
    delete this.model.fattura_persona_fisica_provincia;
  }

  prepareModelForPersonaGiuridica ()
  {
    this.deleteModelPersonaFisica();
  }

  deleteModelPersonaGiuridica ()
  {
    delete this.model.fattura_persona_giuridica_ragione_sociale;
    delete this.model.fattura_persona_giuridica_codice_fiscale;
    delete this.model.fattura_persona_giuridica_indirizzo;
    delete this.model.fattura_persona_giuridica_sdi;
    delete this.model.fattura_giuridica_pec;
  }

  fiscalCodeOnChange() {
    if (this.model.fattura_persona_fisica_codice_fiscale != null && typeof this.model.fattura_persona_fisica_codice_fiscale != "undefined") {
      this.model.fattura_persona_fisica_codice_fiscale = this.model.fattura_persona_fisica_codice_fiscale.toUpperCase();
    }
  }

}
