import { Component, OnInit } from '@angular/core';
import { CampsService } from '../_services/camps.service';

import { AppStateService } from '../_services/appstate.service';

@Component({
  selector: 'app-camps',
  templateUrl: './camps.component.html',
  styleUrls: ['./camps.component.css']
})
export class CampsComponent implements OnInit {

  constructor(private campService: CampsService, private appState: AppStateService) { }


  nextDays: any[] = [];
  filtered: any[] = [];
  showDetails: boolean = false;

  ngOnInit() {

  }


  campChangedEvent ()
  {
    this.filtered = this.appState.filtered;
    this.nextDays = this.appState.nextDays;
    this.showDetails = this.appState.showDetails;
  }

  reserve (camp)
  {
    // console.log(camp);
  }


  public get getCampService() {
    return this.campService;
  }

  public get getAppState() {
    return this.appState;
  }

}
