<form name="form" class="needs-validation" (ngSubmit)="submit()" #form="ngForm" novalidate>
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || week_01.dirty || week_01.touched || week_02.dirty || week_02.touched
                    || week_03.dirty || week_03.touched || week_04.dirty || week_04.touched) &&
                    !weekValid() && !week_01.disabled && !week_02.disabled && !week_03.disabled && !week_04.disabled}"
      >
        <label>Seleziona le Settimane <span>*</span></label>
        <div class="form-check" [hidden]="camp.camp_weeks_num <= 0">
          <input type="checkbox" #week_01 [(ngModel)]="weeks[0].value" [disabled]="weeks[0].locked" [ngModelOptions]="{standalone: true}" class="form-check-input" (change)="onWeekSelect()">
          <label class="form-check-label">Settimana 1 ({{camp.camp_weeks[0]?.start_parsed}} - {{camp.camp_weeks[0]?.end_parsed}})</label><small *ngIf="weeks[0].locked" class="text-muted"><br><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Settimana a numero chiuso, per informazioni e disponibilità contattare +393791001271 oppure scrivere a educocamp@educoitalia.it</small>
        </div>
        <div class="form-check" [hidden]="camp.camp_weeks_num <= 1">
          <input type="checkbox" #week_02 [(ngModel)]="weeks[1].value" [disabled]="weeks[1].locked" [ngModelOptions]="{standalone: true}" class="form-check-input" (change)="onWeekSelect()">
          <label class="form-check-label">Settimana 2 ({{camp.camp_weeks[1]?.start_parsed}} - {{camp.camp_weeks[1]?.end_parsed}})</label><small *ngIf="weeks[1].locked" class="text-muted"><br><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Settimana a numero chiuso, per informazioni e disponibilità contattare +393791001271 oppure scrivere a educocamp@educoitalia.it</small>
        </div>
        <div class="form-check" [hidden]="camp.camp_weeks_num <= 2">
          <input type="checkbox" #week_03 [(ngModel)]="weeks[2].value" [disabled]="weeks[2].locked" [ngModelOptions]="{standalone: true}" class="form-check-input" (change)="onWeekSelect()">
          <label class="form-check-label">Settimana 3 ({{camp.camp_weeks[2]?.start_parsed}} - {{camp.camp_weeks[2]?.end_parsed}})</label><small *ngIf="weeks[2].locked" class="text-muted"><br><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Settimana a numero chiuso, per informazioni e disponibilità contattare +393791001271 oppure scrivere a educocamp@educoitalia.it</small>
        </div>
        <div class="form-check" [hidden]="camp.camp_weeks_num <= 3">
          <input type="checkbox" #week_04 [(ngModel)]="weeks[3].value" [disabled]="weeks[3].locked" [ngModelOptions]="{standalone: true}" class="form-check-input" (change)="onWeekSelect()">
          <label class="form-check-label">Settimana 4 ({{camp.camp_weeks[3]?.start_parsed}} - {{camp.camp_weeks[3]?.end_parsed}})</label><small *ngIf="weeks[3].locked" class="text-muted"><br><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Settimana a numero chiuso, per informazioni e disponibilità contattare +393791001271 oppure scrivere a educocamp@educoitalia.it</small>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="weekSelected">
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_cognome.dirty || figlio_cognome.touched) && !figlio_cognome.valid && !figlio_cognome.disabled }">
          <label>Cognome <span>*</span></label>
          <input [(ngModel)]="model.figlio_cognome" name=figlio_cognome
            #figlio_cognome="ngModel" class="form-control" required
            [ngClass]="{'is-valid': figlio_cognome.dirty && figlio_cognome.touched && figlio_cognome.valid && !figlio_cognome.disabled,
                              'is-invalid': (form.submitted || figlio_cognome.dirty || figlio_cognome.touched) && !figlio_cognome.valid && !figlio_cognome.disabled }">
          <small class="form-text"
            *ngIf="(form.submitted || figlio_cognome.dirty || figlio_cognome.touched) && !figlio_cognome.valid && !figlio_cognome.disabled">Il
            cognome è obbligatorio </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_nome.dirty || figlio_nome.touched) && !figlio_nome.valid && !figlio_nome.disabled }">
          <label>Nome <span>*</span></label>
          <input [(ngModel)]="model.figlio_nome" name=figlio_nome #figlio_nome="ngModel"
            class="form-control" required
            [ngClass]="{'is-valid': figlio_nome.dirty && figlio_nome.touched && figlio_nome.valid && !figlio_nome.disabled,
                              'is-invalid': (form.submitted || figlio_nome.dirty || figlio_nome.touched) && !figlio_nome.valid && !figlio_nome.disabled }">
          <small class="form-text"
            *ngIf="(form.submitted || figlio_nome.dirty || figlio_nome.touched) && !figlio_nome.valid && !figlio_nome.disabled">Il
            nome è obbligatorio </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_codice_fiscale.dirty || figlio_codice_fiscale.touched) && !figlio_codice_fiscale.disabled && !fiscalCodeValid }">
          <label>Codice Fiscale <span>*</span></label>
          <input [(ngModel)]="model.figlio_codice_fiscale" name=figlio_codice_fiscale
            #figlio_codice_fiscale="ngModel" class="form-control" required (input)="fiscalCodeOnChange()"
            [ngClass]="{'is-valid': figlio_codice_fiscale.dirty && figlio_codice_fiscale.touched && !figlio_codice_fiscale.disabled && fiscalCodeValid,
                              'is-invalid': (form.submitted || figlio_codice_fiscale.dirty || figlio_codice_fiscale.touched) && !figlio_codice_fiscale.disabled && !fiscalCodeValid }">
          <small class="form-text"
            *ngIf="(form.submitted || figlio_codice_fiscale.dirty || figlio_codice_fiscale.touched) && !figlio_codice_fiscale.disabled && !fiscalCodeValid">Il
            Codice Fiscale non è valido </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Sesso <span>*</span></label>
          <div class="form-check">
            <input [(ngModel)]="model.figlio_sesso" class="form-check-input" type="radio"
              name="exampleRadios" value="Maschio" checked>
            <label class="form-check-label">Maschio</label>
          </div>
          <div class="form-check">
            <input [(ngModel)]="model.figlio_sesso" class="form-check-input" type="radio"
              name="exampleRadios" value="Femmina">
            <label class="form-check-label">Femmina</label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_data_nascita.dirty || figlio_data_nascita.touched) && !figlio_data_nascita.valid && !figlio_data_nascita.disabled }">
          <label>Data di Nascita <span>*</span></label>
          <input type="date" [(ngModel)]="model.figlio_data_nascita" name=figlio_data_nascita
            #figlio_data_nascita="ngModel" class="form-control" required
            [ngClass]="{'is-valid': figlio_data_nascita.dirty && figlio_data_nascita.touched && figlio_data_nascita.valid && !figlio_data_nascita.disabled,
                              'is-invalid': (form.submitted || figlio_data_nascita.dirty || figlio_data_nascita.touched) && !figlio_data_nascita.valid && !figlio_data_nascita.disabled }">
          <small class="form-text"
            *ngIf="(form.submitted || figlio_data_nascita.dirty || figlio_data_nascita.touched) && !figlio_data_nascita.valid && !figlio_data_nascita.disabled">La
            data di nascita è obbligatoria </small>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_citta_nascita.dirty || figlio_citta_nascita.touched) && !figlio_citta_nascita.valid && !figlio_citta_nascita.disabled }">
          <label>Luogo di Nascita <span>*</span></label>
          <input type="text" [(ngModel)]="model.figlio_citta_nascita" name=figlio_citta_nascita
            #figlio_citta_nascita="ngModel" class="form-control" required
            [ngClass]="{'is-valid': figlio_citta_nascita.dirty && figlio_citta_nascita.touched && figlio_citta_nascita.valid && !figlio_citta_nascita.disabled,
                              'is-invalid': (form.submitted || figlio_citta_nascita.dirty || figlio_citta_nascita.touched) && !figlio_citta_nascita.valid && !figlio_citta_nascita.disabled }">
          <small class="form-text"
            *ngIf="(form.submitted || figlio_citta_nascita.dirty || figlio_citta_nascita.touched) && !figlio_citta_nascita.valid && !figlio_citta_nascita.disabled">Il
            luogo di nascita è obbligatorio, per i nati all'estero, inserire la nazione di nascita senza
            abbreviazioni </small>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_provincia_nascita.dirty || figlio_provincia_nascita.touched) && !figlio_provincia_nascita.valid && !figlio_provincia_nascita.disabled }">
          <label >Provincia di Nascita <span>*</span></label>
          <select [(ngModel)]="model.figlio_provincia_nascita" name=figlio_provincia_nascita
            #figlio_provincia_nascita="ngModel" class="form-control" required
            [ngClass]="{'is-valid': figlio_provincia_nascita.dirty && figlio_provincia_nascita.touched && figlio_provincia_nascita.valid && !figlio_provincia_nascita.disabled,
                              'is-invalid': (form.submitted || figlio_provincia_nascita.dirty || figlio_provincia_nascita.touched) && !figlio_provincia_nascita.valid && !figlio_provincia_nascita.disabled }">
            <option *ngFor="let prov of prov_array" [value]="prov.nome">{{prov.nome}}</option>
          </select>
          <small class="form-text"
            *ngIf="(form.submitted || figlio_provincia_nascita.dirty || figlio_provincia_nascita.touched) && !figlio_provincia_nascita.valid && !figlio_provincia_nascita.disabled">La
            provincia di nascita è obbligatoria </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_indirizzo.dirty || figlio_indirizzo.touched) && !figlio_indirizzo.valid && !figlio_indirizzo.disabled }">
          <label>Indirizzo di Residenza <span>*</span></label>
          <input type="text" [(ngModel)]="model.figlio_indirizzo" name=figlio_indirizzo
            #figlio_indirizzo="ngModel" class="form-control" required
            [ngClass]="{'is-valid': figlio_indirizzo.dirty && figlio_indirizzo.touched && figlio_indirizzo.valid && !figlio_indirizzo.disabled,
                              'is-invalid': (form.submitted || figlio_indirizzo.dirty || figlio_indirizzo.touched) && !figlio_indirizzo.valid && !figlio_indirizzo.disabled }">
          <small class="form-text"
            *ngIf="(form.submitted || figlio_indirizzo.dirty || figlio_indirizzo.touched) && !figlio_indirizzo.valid && !figlio_indirizzo.disabled">L'indirizzo
            di residenza è obbligatorio </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_citta.dirty || figlio_citta.touched) && !figlio_citta.valid && !figlio_citta.disabled }">
          <label>Località <span>*</span></label>
          <input type="text" [(ngModel)]="model.figlio_citta" name=figlio_citta
            #figlio_citta="ngModel" class="form-control" required
            [ngClass]="{'is-valid': figlio_citta.dirty && figlio_citta.touched && figlio_citta.valid && !figlio_citta.disabled,
                              'is-invalid': (form.submitted || figlio_citta.dirty || figlio_citta.touched) && !figlio_citta.valid && !figlio_citta.disabled }">
          <small class="form-text"
            *ngIf="(form.submitted || figlio_citta.dirty || figlio_citta.touched) && !figlio_citta.valid && !figlio_citta.disabled">La
            località di residenza è obbligatoria </small>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_cap.dirty || figlio_cap.touched) && !figlio_cap.valid && !figlio_cap.disabled }">
          <label>CAP <span>*</span></label>
          <input type="number" [(ngModel)]="model.figlio_cap" name=figlio_cap
            #figlio_cap="ngModel" class="form-control" required
            [ngClass]="{'is-valid': figlio_cap.dirty && figlio_cap.touched && figlio_cap.valid && !figlio_cap.disabled,
                              'is-invalid': (form.submitted || figlio_cap.dirty || figlio_cap.touched) && !figlio_cap.valid && !figlio_cap.disabled }">
          <small class="form-text"
            *ngIf="(form.submitted || figlio_cap.dirty || figlio_cap.touched) && !figlio_cap.valid && !figlio_cap.disabled">Il
            CAP di residenza è obbligatorio </small>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_provincia.dirty || figlio_provincia.touched) && !figlio_provincia.valid && !figlio_provincia.disabled }">
          <label>Provincia <span>*</span></label>
          <select [(ngModel)]="model.figlio_provincia" name=figlio_provincia
            #figlio_provincia="ngModel" class="form-control" required
            [ngClass]="{'is-valid': figlio_provincia.dirty && figlio_provincia.touched && figlio_provincia.valid && !figlio_provincia.disabled,
                              'is-invalid': (form.submitted || figlio_provincia.dirty || figlio_provincia.touched) && !figlio_provincia.valid && !figlio_provincia.disabled }">
            <option *ngFor="let prov of prov_array" [value]="prov.nome">{{prov.nome}}</option>
          </select>
          <small class="form-text"
            *ngIf="(form.submitted || figlio_provincia.dirty || figlio_provincia.touched) && !figlio_provincia.valid && !figlio_provincia.disabled">La
            provincia di residenza è obbligatoria </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_cellulare.dirty || figlio_cellulare.touched) && !figlio_cellulare.valid && !figlio_cellulare.disabled }">
          <label>Cellulare <span>*</span></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">+39</span>
            </div>
            <input type="number" [(ngModel)]="model.figlio_cellulare" name=figlio_cellulare
              #figlio_cellulare="ngModel" class="form-control" required
              [ngClass]="{'is-valid': figlio_cellulare.dirty && figlio_cellulare.touched && figlio_cellulare.valid && !figlio_cellulare.disabled,
                                'is-invalid': (form.submitted || figlio_cellulare.dirty || figlio_cellulare.touched) && !figlio_cellulare.valid && !figlio_cellulare.disabled }">
          </div>
          <small class="form-text"
            *ngIf="(form.submitted || figlio_cellulare.dirty || figlio_cellulare.touched) && !figlio_cellulare.valid && !figlio_cellulare.disabled">Il
            numero di Cellulare è obbligatorio </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || figlio_email.dirty || figlio_email.touched) && !figlio_email.valid && !figlio_email.disabled }">
          <label>Email <span>*</span></label>
          <input type="email" [(ngModel)]="model.figlio_email" name=figlio_email
            #figlio_email="ngModel" class="form-control" required email (input)="emailOnChange()"
            [ngClass]="{'is-valid': figlio_email.dirty && figlio_email.touched && figlio_email.valid && !figlio_email.disabled,
                              'is-invalid': (form.submitted || figlio_email.dirty || figlio_email.touched) && !figlio_email.valid && !figlio_email.disabled }">
          <small class="form-text" [hidden]="!figlio_email.errors?.required"
            *ngIf="(form.submitted || figlio_email.dirty || figlio_email.touched) && !figlio_email.valid && !figlio_email.disabled">La
            mail è obbligatoria </small>
          <small class="form-text" [hidden]="!figlio_email.errors?.email"
            *ngIf="(form.submitted || figlio_email.dirty || figlio_email.touched) && !figlio_email.valid && !figlio_email.disabled">La
            mail non è valida </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || confirm_email.dirty || confirm_email.touched) && confirm_email.value != figlio_email.value && !confirm_email.disabled }">
          <label>Conferma l'email <span>*</span></label>
          <input type="confirm_email" [(ngModel)]="model.confirm_email" name=confirm_email (input)="confirmEmailOnChange()"
            #confirm_email="ngModel" class="form-control"
            [ngClass]="{'is-valid': confirm_email.dirty && confirm_email.touched && confirm_email.value == figlio_email.value && !confirm_email.disabled,
                              'is-invalid': (form.submitted || confirm_email.dirty || confirm_email.touched) && confirm_email.value != figlio_email.value && !confirm_email.disabled }">
          <small class="form-text"
            *ngIf="(form.submitted || confirm_email.dirty || confirm_email.touched) && confirm_email.value != figlio_email.value && !confirm_email.disabled">La
            mail non è uguale </small>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'text-danger': (form.submitted || figlio_scuola_tipo.dirty || figlio_scuola_tipo.touched) && !figlio_scuola_tipo.valid && !figlio_scuola_tipo.disabled }">
          <label>Tipo di scuola <span>*</span></label>
          <select [(ngModel)]="model.figlio_scuola_tipo" name=figlio_scuola_tipo
            #figlio_scuola_tipo="ngModel" class="form-control" required (change)="onSchoolChange()"
            [ngClass]="{'is-valid': figlio_scuola_tipo.dirty && figlio_scuola_tipo.touched && figlio_scuola_tipo.valid && !figlio_scuola_tipo.disabled,
                              'is-invalid': (form.submitted || figlio_scuola_tipo.dirty || figlio_scuola_tipo.touched) && !figlio_scuola_tipo.valid && !figlio_scuola_tipo.disabled }">
        <option></option>
        <option *ngFor="let class of schoolType" [value]="class.name">{{class.name}}</option>
        </select>
          <small class="form-text" [hidden]="!figlio_scuola_tipo.errors?.required"
            *ngIf="(form.submitted || figlio_scuola_tipo.dirty || figlio_scuola_tipo.touched) && !figlio_scuola_tipo.valid && !figlio_scuola_tipo.disabled">La
            scuola è obbligatoria </small>
        </div>
      </div>
      <div class="col-md-6 text-center" *ngIf="model.figlio_scuola_tipo">
        <label for="figlio_scuola_classeInput">Classe <span>*</span></label>
        <div class="form-group">
          <div class="form-check form-check-inline" *ngFor="let classType of currentClassType; let i = index">
            <input class="form-check-input" type="radio" #radio name="inlineRadioOptions" value="{{classType}}" [checked]="i == model.figlio_scuola_classe" (change)="changeRadioClass(radio.value)">
            <label class="form-check-label">{{classType}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Allergie</label>
            <textarea row="3" [(ngModel)]="model.figlio_note" name=figlio_note placeholder="Allergie, indicazioni..."
                      #figlio_note="ngModel" class="form-control" value="">
            </textarea>
          </div>
        </div>
      </div>

    <div *ngIf="section == currentSection">
      <hr>
      <div class="form-group row" *ngIf="section == currentSection">
        <div class="col-4">
          <button type="button" (click)="indietro()" class="btn btn-light btn-block"><i class="fas fa-arrow-left"></i>&nbsp; Indietro</button>
        </div>
        <div class="col-8">
          <button type="submit" class="btn btn-primary btn-block">Avanti</button>
        </div>
      </div>
    </div>
  </div>

</form>
