<!-- SEZIONE DEDICATA ALLA RICERCA -->
<div class="row mt-5" *ngIf="!getCampsService.isOngoingRequest" [hidden]="hideForm">
  <div class="col-md-12">
    <!-- <h1 class="text-center">Ricerca i campi disponibili</h1> -->
    <h2 class="text-center">{{originalCamps.length}} campi disponibili in tutta Italia e all'estero</h2>

    <h5 *ngIf="getAppState.searchModel.audience" class="mt-3 text-center"><i
        class="fas fa-graduation-cap"></i>&nbsp;{{getAppState.searchModel.audience.value}}<br><small>({{getAppState.searchModel.audience.age}}
        anni)</small></h5>
    <h5 *ngIf="getAppState.searchModel.regionprovince" class="mt-3 text-center">Regione/Provincia:
      {{getAppState.searchModel.regionprovince}}</h5>
    <br>
    <form autocomplete="off">

      <div class="row mt-5" *ngIf="!getAppState.searchModel.audience">
        <h5 class="col-md-12 text-center">Scegli la scuola e l'età della persona che parteciperà al camp.</h5>
        <div class="col-sm-12 col-md col-lg" *ngFor="let key of objectKeys(audience_name_available)">
          <div class="form-group">
            <button type="button" class="btn btn-outline-primary btn-block mt-2" (click)="filterByAudience(key)"><i
                class="fas fa-graduation-cap"></i>&nbsp;{{audience_name_available[key].value}}<br><small>({{audience_name_available[key].age}}
                anni)</small></button>
          </div>
        </div>
      </div>

      <div class="row mt-5" *ngIf="!getAppState.searchModel.audience">
        <h5 class="col-md-12 text-center">Conosci il Camp Code?</h5>
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" class="form-control" id="inputCampCode" aria-describedby="campCodeHelp"
              [(ngModel)]="getAppState.searchModel.campCode" name="campCode" placeholder="CampCode-00">
            <small id="campCodeHelp" class="form-text text-muted">Il Camp code è un codice alfanumerico che ti avrà
              detto l'insegnate, se non conosci questo codice non preoccuparti, esegui la ricerca tramite i pulsanti
              sopra</small>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <button type="button" class="btn btn-primary btn-block" (click)="filterByCampCode()">Cerca il Camp
              Code</button>
          </div>
        </div>
      </div>

      <p class="text-center mt-2">
        <a href="https://www.educoitalia.it/condizioni-di-annullamento-iscrizione-educo-camp-e-rimborso/"
          target="_blank">Scopri le nostre condizioni di annullamento e rimborsi</a>
      </p>

      <div class="row mt-5" *ngIf="getAppState.searchModel.audience && !getAppState.searchModel.regionprovince">
        <h5 class="col-md-12 text-center">Puoi scegliere di filtrare per Regione o Provincia, oppure consultare la lista
          di tutti i camp in basso</h5>
        <div class="col-md-12">
          <div class="form-group">
            <label for="regionprovinceInput">Regioni/Province</label>
            <select [(ngModel)]="getAppState.searchModel.regionprovince" name=campregionprovince class="form-control"
              id="regionprovinceInput" (change)="changeFilters()">
              <option value="">Mostra tutte le regioni e province</option>
              <option *ngFor="let rp of regionsAndProvince" [value]="rp.value">
                <span *ngIf="rp.type=='region'">{{rp.text}}</span>
                <span *ngIf="rp.type=='province'">&nbsp;&nbsp;&nbsp;&nbsp;{{rp.text}}</span>
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- <div class="row" *ngIf="getAppState.searchModel.audience && getAppState.searchModel.regionprovince">
        <div class="col-md-12">
          <div class="form-group">
            <label for="monthInput">Mese</label>
            <select [(ngModel)]="getAppState.searchModel.month" name="campmonth" class="form-control" id="monthInput"
              (change)="changeFilters()">
              <option value="">Tutto l'anno</option>
              <option *ngFor="let month of months" [value]="month.value">{{month.text}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="camptypeInput">Tipologia del Camp</label>
            <ng-template #customItemTemplate let-model="item">
              <span>{{getAppState.searchModel.text}}</span>
            </ng-template>
            <input [(ngModel)]="getAppState.searchModel.campType" name="camptype" [typeahead]="campTypes"
              [typeaheadItemTemplate]="customItemTemplate" typeaheadOptionField="name" typeaheadGroupField="type"
              [typeaheadMinLength]="0" class="form-control" (typeaheadOnSelect)="changeFilters()"
              (change)="changeFilters()">
          </div>
        </div>
      </div> -->

      <div class="row mt-5" *ngIf="getAppState.searchModel.audience">
        <h5 class="col-md-12 text-center">Se non trovi quello che stavi cercando puoi ripartire da zero</h5>
        <div class="col-md-12">
          <div class="form-group">
            <button type="button" class="btn btn-danger btn-block" (click)="resetFilter()"><i
                class="far fa-trash-alt"></i>&nbsp;Pulisci e ricomincia</button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="getAppState.searchModel.audience && getAppState.filtered.length == 0">
        <div class="col-md-12">
          <alert type="danger">
            <strong>Nessun risultato</strong> con i filtri selezionati.
          </alert>
        </div>
      </div>
    </form>
  </div>
</div>
