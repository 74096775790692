import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BackendService } from './backend.service';
import { IpService } from './ip.service';
import { AppStateService } from './appstate.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CampsService {

  private subjectToUpdate: Subject<boolean> = new Subject<boolean>();
  ongoingRequest: boolean = false;

  //  informazioni browser e ip utente
  private ipData;

  public allCamps: any = {};
  public currentSubscriber: any = {};

  public audience_name_available: any = {};
  public campScheduleAudience =
    [
      {
        key: '0',
        value: 'D\'infanzia',
        age: '4-5'
      },
      {
        key: '1',
        value: 'Primaria',
        age: '6-10'
      },
      {
        key: '2',
        value: 'Secondaria di I°',
        age: '11-13'
      },
      {
        key: '3',
        value: 'Secondaria di II°',
        age: '14-18'
      },
      {
        key: '4',
        value: 'Università',
        age: '18+'
      }
    ];

  constructor(private backend: BackendService, private ipService: IpService,
    private appState: AppStateService) {

    //  recupero subito l'ip da utilizzare
    this.ipService.getIp((jsonResponse) => {

      this.ipData = jsonResponse;
    });
  }

  getsubjectToUpdateObservable(): Observable<boolean> {
    return this.subjectToUpdate.asObservable();
  }

  startRequest() {
    this.ongoingRequest = true;
  }

  finishRequest() {
    this.ongoingRequest = false;
  }

  get isOngoingRequest() {
    return this.ongoingRequest;
  }

  createArrayCamp() {
    if (this.allCamps == null) return;

    let self = this;

    //  costruisco l'array dei corsi per tipologia, provincia e regione
    this.allCamps.forEach(camp => {

      if (camp.audience != null) {

        let audienceModel = JSON.parse(camp.audience);
        camp.audience_name = [];

        for (let a in audienceModel) {

          if (audienceModel[a]) {

            self.campScheduleAudience.forEach(e => {

              if (e.key == a) {
                camp.audience_name.push(e.value);
                self.audience_name_available[e.key] = e;
              }
            });
          }
        }
      }
    });

    this.appState.filtered = JSON.parse(JSON.stringify(this.allCamps));
  }

  public getAllCampsAvailable(callbackSuccess: any = null, callbackError: any = null) {
    this.startRequest();

    this.backend.get('campseducoitalia/getallcamps', new HttpParams).subscribe(
      (response) => {
        this.finishRequest();

        var res = response;

        this.allCamps = res;
        this.createArrayCamp();

        if (callbackSuccess != null)
          callbackSuccess(res);

      }, (error) => {

        this.finishRequest();

        this.backend.showErrors(error, 'get');

        if (callbackError != null)
          callbackError();
      });

  }


  public saveSubscription(model: any, callbackSuccess: any = null, callbackError: any = null) {
    this.startRequest();

    //  se non ho i dati dell'ip dell'utente li recupero prima
    if (this.ipData == null) {
      this.ipService.getIp((jsonResponse) => {

        this.ipData = jsonResponse;
        this.saveSubscriptionImmediately(model, callbackSuccess, callbackError);
      });
    }
    else {
      this.saveSubscriptionImmediately(model, callbackSuccess, callbackError);
    }

  }

  protected saveSubscriptionImmediately(model: any, callbackSuccess: any = null, callbackError: any = null) {
    var date = new Date();
    date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    model.user_ip = this.ipData.ip;
    model.user_agent = navigator.userAgent;
    model.user_date = date;

    this.backend.post('campseducoitalia/savesignup', model).subscribe(
      (response) => {
        this.finishRequest();

        var res = response;
        this.currentSubscriber = res;
        this.currentSubscriber.selectedBookingData = model.selectedBookingData;

        if (callbackSuccess != null)
          callbackSuccess(res);

      }, (error) => {

        this.finishRequest();

        this.backend.showErrors(error, 'post');

        if (callbackError != null)
          callbackError();
      });
  }



}
