<form name="form" class="needs-validation" (ngSubmit)="submit()" #form="ngForm" novalidate>
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_grado_parentela.dirty || genitore_grado_parentela.touched) && !genitore_grado_parentela.valid && !genitore_grado_parentela.disabled }"
      >
      <label for="genitore_grado_parentelaInput">Grado di Parentela <span>*</span></label>
      <select [(ngModel)]="model.genitore_grado_parentela" name=genitore_grado_parentela
          #genitore_grado_parentela="ngModel" class="form-control"
          id="genitore_grado_parentelaInput" required
          [ngClass]="{'is-valid': genitore_grado_parentela.dirty && genitore_grado_parentela.touched && genitore_grado_parentela.valid && !genitore_grado_parentela.disabled,
                            'is-invalid': (form.submitted || genitore_grado_parentela.dirty || genitore_grado_parentela.touched) && !genitore_grado_parentela.valid && !genitore_grado_parentela.disabled }">
          <option [value]="'madre'">Madre</option>
          <option [value]="'padre'">Padre</option>
          <option [value]="'altro'">Altro</option>
        </select>
        <small class="form-text"
          *ngIf="(form.submitted || genitore_grado_parentela.dirty || genitore_grado_parentela.touched) && !genitore_grado_parentela.valid && !genitore_grado_parentela.disabled">Il
          grado di parentela è obbligatorio </small>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_cognome.dirty || genitore_cognome.touched) && !genitore_cognome.valid && !genitore_cognome.disabled }">
        <label for="genitore_cognomeInput">Cognome <span>*</span></label>
        <input [(ngModel)]="model.genitore_cognome" name=genitore_cognome
          #genitore_cognome="ngModel" class="form-control" id="genitore_cognomeInput" required
          [ngClass]="{'is-valid': genitore_cognome.dirty && genitore_cognome.touched && genitore_cognome.valid && !genitore_cognome.disabled,
                            'is-invalid': (form.submitted || genitore_cognome.dirty || genitore_cognome.touched) && !genitore_cognome.valid && !genitore_cognome.disabled }">
        <small class="form-text"
          *ngIf="(form.submitted || genitore_cognome.dirty || genitore_cognome.touched) && !genitore_cognome.valid && !genitore_cognome.disabled">Il
          cognome è obbligatorio </small>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_nome.dirty || genitore_nome.touched) && !genitore_nome.valid && !genitore_nome.disabled }">
        <label for="nameInput">Nome <span>*</span></label>
        <input [(ngModel)]="model.genitore_nome" name=genitore_nome #genitore_nome="ngModel"
          class="form-control" id="nameInput" required
          [ngClass]="{'is-valid': genitore_nome.dirty && genitore_nome.touched && genitore_nome.valid && !genitore_nome.disabled,
                            'is-invalid': (form.submitted || genitore_nome.dirty || genitore_nome.touched) && !genitore_nome.valid && !genitore_nome.disabled }">
        <small class="form-text"
          *ngIf="(form.submitted || genitore_nome.dirty || genitore_nome.touched) && !genitore_nome.valid && !genitore_nome.disabled">Il
          nome è obbligatorio </small>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_codice_fiscale.dirty || genitore_codice_fiscale.touched) && !genitore_codice_fiscale.disabled && !fiscalCodeValid }">
        <label for="genitore_codice_fiscaleInput">Codice Fiscale <span>*</span></label>
        <input [(ngModel)]="model.genitore_codice_fiscale" name=genitore_codice_fiscale
          #genitore_codice_fiscale="ngModel" class="form-control" id="genitore_codice_fiscaleInput"
          required (input)="fiscalCodeOnChange()"
          [ngClass]="{'is-valid': genitore_codice_fiscale.dirty && genitore_codice_fiscale.touched && !genitore_codice_fiscale.disabled && fiscalCodeValid,
                            'is-invalid': (form.submitted || genitore_codice_fiscale.dirty || genitore_codice_fiscale.touched) && !genitore_codice_fiscale.disabled && !fiscalCodeValid }">
        <small class="form-text"
          *ngIf="(form.submitted || genitore_codice_fiscale.dirty || genitore_codice_fiscale.touched) && !genitore_codice_fiscale.disabled && !fiscalCodeValid">Il
          Codice Fiscale non è valido </small>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Sesso <span>*</span></label>
        <div class="form-check">
          <input [(ngModel)]="model.genitore_sesso" class="form-check-input" type="radio"
            name="exampleRadios" id="gender1Input" value="Maschio" checked>
          <label class="form-check-label" for="gender1Input">Maschio</label>
        </div>
        <div class="form-check">
          <input [(ngModel)]="model.genitore_sesso" class="form-check-input" type="radio"
            name="exampleRadios" id="gender2Input" value="Femmina">
          <label class="form-check-label" for="gender2Input">Femmina</label>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_data_nascita.dirty || genitore_data_nascita.touched) && !genitore_data_nascita.valid && !genitore_data_nascita.disabled }">
        <label for="genitore_data_nascitaInput">Data di Nascita <span>*</span></label>
        <input type="date" [(ngModel)]="model.genitore_data_nascita" name=genitore_data_nascita
          #genitore_data_nascita="ngModel" class="form-control" id="genitore_data_nascitaInput"
          required
          [ngClass]="{'is-valid': genitore_data_nascita.dirty && genitore_data_nascita.touched && genitore_data_nascita.valid && !genitore_data_nascita.disabled,
                            'is-invalid': (form.submitted || genitore_data_nascita.dirty || genitore_data_nascita.touched) && !genitore_data_nascita.valid && !genitore_data_nascita.disabled }">
        <small class="form-text"
          *ngIf="(form.submitted || genitore_data_nascita.dirty || genitore_data_nascita.touched) && !genitore_data_nascita.valid && !genitore_data_nascita.disabled">La
          data di nascita è obbligatoria </small>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_citta_nascita.dirty || genitore_citta_nascita.touched) && !genitore_citta_nascita.valid && !genitore_citta_nascita.disabled }">
        <label for="genitore_citta_nascitaInput">Luogo di Nascita <span>*</span></label>
        <input type="text" [(ngModel)]="model.genitore_citta_nascita" name=genitore_citta_nascita
          #genitore_citta_nascita="ngModel" class="form-control" id="genitore_citta_nascitaInput"
          required
          [ngClass]="{'is-valid': genitore_citta_nascita.dirty && genitore_citta_nascita.touched && genitore_citta_nascita.valid && !genitore_citta_nascita.disabled,
                            'is-invalid': (form.submitted || genitore_citta_nascita.dirty || genitore_citta_nascita.touched) && !genitore_citta_nascita.valid && !genitore_citta_nascita.disabled }">
        <small class="form-text"
          *ngIf="(form.submitted || genitore_citta_nascita.dirty || genitore_citta_nascita.touched) && !genitore_citta_nascita.valid && !genitore_citta_nascita.disabled">Il
          luogo di nascita è obbligatorio, per i nati all'estero, inserire la nazione di nascita senza
          abbreviazioni </small>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_provincia_nascita.dirty || genitore_provincia_nascita.touched) && !genitore_provincia_nascita.valid && !genitore_provincia_nascita.disabled }">
        <label for="genitore_provincia_nascitaInput">Provincia di Nascita <span>*</span></label>
        <select [(ngModel)]="model.genitore_provincia_nascita" name=genitore_provincia_nascita
          #genitore_provincia_nascita="ngModel" class="form-control"
          id="genitore_provincia_nascitaInput" required
          [ngClass]="{'is-valid': genitore_provincia_nascita.dirty && genitore_provincia_nascita.touched && genitore_provincia_nascita.valid && !genitore_provincia_nascita.disabled,
                            'is-invalid': (form.submitted || genitore_provincia_nascita.dirty || genitore_provincia_nascita.touched) && !genitore_provincia_nascita.valid && !genitore_provincia_nascita.disabled }">
          <option *ngFor="let prov of prov_array" [value]="prov.nome">{{prov.nome}}</option>
        </select>
        <small class="form-text"
          *ngIf="(form.submitted || genitore_provincia_nascita.dirty || genitore_provincia_nascita.touched) && !genitore_provincia_nascita.valid && !genitore_provincia_nascita.disabled">La
          provincia di nascita è obbligatoria </small>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_indirizzo.dirty || genitore_indirizzo.touched) && !genitore_indirizzo.valid && !genitore_indirizzo.disabled }">
        <label for="genitore_indirizzoInput">Indirizzo di Residenza <span>*</span></label>
        <input type="text" [(ngModel)]="model.genitore_indirizzo" name=genitore_indirizzo
          #genitore_indirizzo="ngModel" class="form-control" id="genitore_indirizzoInput" required
          [ngClass]="{'is-valid': genitore_indirizzo.dirty && genitore_indirizzo.touched && genitore_indirizzo.valid && !genitore_indirizzo.disabled,
                            'is-invalid': (form.submitted || genitore_indirizzo.dirty || genitore_indirizzo.touched) && !genitore_indirizzo.valid && !genitore_indirizzo.disabled }">
        <small class="form-text"
          *ngIf="(form.submitted || genitore_indirizzo.dirty || genitore_indirizzo.touched) && !genitore_indirizzo.valid && !genitore_indirizzo.disabled">L'indirizzo
          di residenza è obbligatorio </small>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_citta.dirty || genitore_citta.touched) && !genitore_citta.valid && !genitore_citta.disabled }">
        <label for="genitore_cittaInput">Località <span>*</span></label>
        <input type="text" [(ngModel)]="model.genitore_citta" name=genitore_citta
          #genitore_citta="ngModel" class="form-control" id="genitore_cittaInput" required
          [ngClass]="{'is-valid': genitore_citta.dirty && genitore_citta.touched && genitore_citta.valid && !genitore_citta.disabled,
                            'is-invalid': (form.submitted || genitore_citta.dirty || genitore_citta.touched) && !genitore_citta.valid && !genitore_citta.disabled }">
        <small class="form-text"
          *ngIf="(form.submitted || genitore_citta.dirty || genitore_citta.touched) && !genitore_citta.valid && !genitore_citta.disabled">La
          località di residenza è obbligatoria </small>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_cap.dirty || genitore_cap.touched) && !genitore_cap.valid && !genitore_cap.disabled }">
        <label for="genitore_capInput">CAP <span>*</span></label>
        <input type="number" [(ngModel)]="model.genitore_cap" name=genitore_cap
          #genitore_cap="ngModel" class="form-control" id="genitore_capInput" required
          [ngClass]="{'is-valid': genitore_cap.dirty && genitore_cap.touched && genitore_cap.valid && !genitore_cap.disabled,
                            'is-invalid': (form.submitted || genitore_cap.dirty || genitore_cap.touched) && !genitore_cap.valid && !genitore_cap.disabled }">
        <small class="form-text"
          *ngIf="(form.submitted || genitore_cap.dirty || genitore_cap.touched) && !genitore_cap.valid && !genitore_cap.disabled">Il
          CAP di residenza è obbligatorio </small>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_provincia.dirty || genitore_provincia.touched) && !genitore_provincia.valid && !genitore_provincia.disabled }">
        <label for="genitore_provinciaInput">Provincia <span>*</span></label>
        <select [(ngModel)]="model.genitore_provincia" name=genitore_provincia
          #genitore_provincia="ngModel" class="form-control" id="genitore_provinciaInput" required
          [ngClass]="{'is-valid': genitore_provincia.dirty && genitore_provincia.touched && genitore_provincia.valid && !genitore_provincia.disabled,
                            'is-invalid': (form.submitted || genitore_provincia.dirty || genitore_provincia.touched) && !genitore_provincia.valid && !genitore_provincia.disabled }">
          <option *ngFor="let prov of prov_array" [value]="prov.nome">{{prov.nome}}</option>
        </select>
        <small class="form-text"
          *ngIf="(form.submitted || genitore_provincia.dirty || genitore_provincia.touched) && !genitore_provincia.valid && !genitore_provincia.disabled">La
          provincia di residenza è obbligatoria </small>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_cellulare.dirty || genitore_cellulare.touched) && !genitore_cellulare.valid && !genitore_cellulare.disabled }">
        <label for="genitore_cellulareInput">Cellulare <span>*</span></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="genitore_cellulareInput">+39</span>
          </div>
          <input type="number" [(ngModel)]="model.genitore_cellulare" name=genitore_cellulare
            #genitore_cellulare="ngModel" class="form-control" id="genitore_cellulareInput" required
            [ngClass]="{'is-valid': genitore_cellulare.dirty && genitore_cellulare.touched && genitore_cellulare.valid && !genitore_cellulare.disabled,
                              'is-invalid': (form.submitted || genitore_cellulare.dirty || genitore_cellulare.touched) && !genitore_cellulare.valid && !genitore_cellulare.disabled }">
        </div>
        <small class="form-text"
          *ngIf="(form.submitted || genitore_cellulare.dirty || genitore_cellulare.touched) && !genitore_cellulare.valid && !genitore_cellulare.disabled">Il
          numero di Cellulare è obbligatorio </small>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="genitore_telefono_casaInput">Telefono abitazione</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="mobile_phoneInput">+39</span>
          </div>
          <input type="number" [(ngModel)]="model.genitore_telefono_casa" name=genitore_telefono_casa
            #genitore_telefono_casa="ngModel" class="form-control" id="genitore_telefono_casaInput">
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || genitore_email.dirty || genitore_email.touched) && !genitore_email.valid && !genitore_email.disabled }">
        <label for="genitore_emailInput">Email <span>*</span></label>
        <input type="email" [(ngModel)]="model.genitore_email" name=genitore_email
          #genitore_email="ngModel" class="form-control" id="genitore_emailInput" required email (input)="emailOnChange()"
          [ngClass]="{'is-valid': genitore_email.dirty && genitore_email.touched && genitore_email.valid && !genitore_email.disabled,
                            'is-invalid': (form.submitted || genitore_email.dirty || genitore_email.touched) && !genitore_email.valid && !genitore_email.disabled }">
        <small class="form-text" [hidden]="!genitore_email.errors?.required"
          *ngIf="(form.submitted || genitore_email.dirty || genitore_email.touched) && !genitore_email.valid && !genitore_email.disabled">La
          mail è obbligatoria </small>
        <small class="form-text" [hidden]="!genitore_email.errors?.email"
          *ngIf="(form.submitted || genitore_email.dirty || genitore_email.touched) && !genitore_email.valid && !genitore_email.disabled">La
          mail non è valida </small>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || confirm_email.dirty || confirm_email.touched) && confirm_email.value != genitore_email.value && !confirm_email.disabled }">
        <label for="confirm_emailInput">Conferma l'email <span>*</span></label>
        <input type="confirm_email" [(ngModel)]="model.confirm_email" name=confirm_email
          #confirm_email="ngModel" class="form-control" id="confirm_emailInput" (input)="confirmEmailOnChange()"
          [ngClass]="{'is-valid': confirm_email.dirty && confirm_email.touched && confirm_email.value == genitore_email.value && !confirm_email.disabled,
                            'is-invalid': (form.submitted || confirm_email.dirty || confirm_email.touched) && confirm_email.value != genitore_email.value && !confirm_email.disabled }">
        <small class="form-text"
          *ngIf="(form.submitted || confirm_email.dirty || confirm_email.touched) && confirm_email.value != genitore_email.value && !confirm_email.disabled">La
          mail non è uguale </small>
      </div>
    </div>
  </div>

  <div *ngIf="section == currentSection">
    <hr>
    <div class="form-group row" *ngIf="section == currentSection">
      <div class="col-md-12">
        <button type="submit" class="btn btn-primary btn-block">Avanti</button>
      </div>
    </div>
  </div>

</form>