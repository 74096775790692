<form name="form" class="needs-validation" (ngSubmit)="submit()" #form="ngForm" novalidate>
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Volete ospitare dei tutors madrelingua? <span>*</span></label>
          <div class="form-check">
            <input [(ngModel)]="model.ospitare_trainee_tutor" class="form-check-input" type="radio"
              name="exampleRadios" id="traineeTutor1Input" value="No" checked>
            <label class="form-check-label" for="traineeTutor1Input">No</label>
          </div>
          <div class="form-check">
            <input [(ngModel)]="model.ospitare_trainee_tutor" class="form-check-input" type="radio"
              name="exampleRadios" id="traineeTutor2Input" value="Si">
            <label class="form-check-label" for="traineeTutor2Input">Si</label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="model.ospitare_trainee_tutor == 'Si'">
      <div class="col-md-3">
        <div class="form-check" *ngIf="camp.camp_weeks_num > 0">
          <input type="checkbox" [(ngModel)]="model.ospitare_trainee_tutor_week_01" [ngModelOptions]="{standalone: true}" class="form-check-input" id="week_01">
          <label class="form-check-label" for="week_01">Settimana 1 ({{camp.camp_weeks[0].start_parsed}} - {{camp.camp_weeks[0].end_parsed}})</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-check" *ngIf="camp.camp_weeks_num > 1">
          <input type="checkbox" [(ngModel)]="model.ospitare_trainee_tutor_week_02" [ngModelOptions]="{standalone: true}" class="form-check-input" id="week_02">
          <label class="form-check-label" for="week_02">Settimana 2 ({{camp.camp_weeks[1].start_parsed}} - {{camp.camp_weeks[1].end_parsed}})</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-check" *ngIf="camp.camp_weeks_num > 2">
          <input type="checkbox" [(ngModel)]="model.ospitare_trainee_tutor_week_03" [ngModelOptions]="{standalone: true}" class="form-check-input" id="week_03">
          <label class="form-check-label" for="week_03">Settimana 3 ({{camp.camp_weeks[2].start_parsed}} - {{camp.camp_weeks[2].end_parsed}})</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-check" *ngIf="camp.camp_weeks_num > 3">
          <input type="checkbox" [(ngModel)]="model.ospitare_trainee_tutor_week_04" [ngModelOptions]="{standalone: true}" class="form-check-input" id="week_04">
          <label class="form-check-label" for="week_04">Settimana 4 ({{camp.camp_weeks[3].start_parsed}} - {{camp.camp_weeks[3].end_parsed}})</label>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="model.ospitare_trainee_tutor == 'Si'">
      <div class="col-md-3">
        <div class="form-group"
          [ngClass]="{ 'text-danger': (form.submitted || quanti_ospitare_trainee_tutor.dirty || quanti_ospitare_trainee_tutor.touched) && !quanti_ospitare_trainee_tutor.valid && !quanti_ospitare_trainee_tutor.disabled }"></div>
          <label>Quanti Tutor può ospitare? <span>*</span></label>
          <select [(ngModel)]="model.quanti_ospitare_trainee_tutor" name=quanti_ospitare_trainee_tutor
          #quanti_ospitare_trainee_tutor="ngModel" class="form-control" required
          [ngClass]="{'is-valid': quanti_ospitare_trainee_tutor.dirty && quanti_ospitare_trainee_tutor.touched && quanti_ospitare_trainee_tutor.valid && !quanti_ospitare_trainee_tutor.disabled,
                            'is-invalid': (form.submitted || quanti_ospitare_trainee_tutor.dirty || quanti_ospitare_trainee_tutor.touched) && !quanti_ospitare_trainee_tutor.valid && !quanti_ospitare_trainee_tutor.disabled }">
          <option></option>
          <option *ngFor="let t of arrayTutor" [value]="t">{{t}}</option>
        </select>
      </div>
    </div>

    <div *ngIf="section == currentSection">
      <hr>
      <div class="form-group row">
        <div class="col-4">
          <button type="button" (click)="indietro()" class="btn btn-light btn-block"><i class="fas fa-arrow-left"></i>&nbsp; Indietro</button>
        </div>
        <div class="col-8">
          <button type="submit" class="btn btn-primary btn-block">Avanti</button>
        </div>
      </div>
    </div>
    
  </form>