import { Component } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Educo Camps';

  constructor(private notify: NotificationsService){}

  notificationOptions = {
    position: ["top", "right"],
    timeOut: 1500,
    theClass: "simpleNotifications"
  }
}
