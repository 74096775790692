import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Province } from '../_utils/province';
import { format } from 'date-fns';
import CodiceFiscale from 'codice-fiscale-js';
import { NotificationsService } from 'angular2-notifications';
import { CampsService } from '../_services/camps.service';

@Component({
  selector: 'app-camp-booking-form-parent',
  templateUrl: './camp-booking-form-parent.component.html',
  styleUrls: ['./camp-booking-form-parent.component.css']
})
export class CampBookingFormParentComponent implements OnInit {

  @ViewChild("form", { static: false }) form;

  @Input() currentSection: number;
  @Input() section: number = 0;
  @Output() nextPressed: EventEmitter<any> = new EventEmitter<any>();

  public prov_array = Province;
  @Input() completeModel: any;
  public model:any;

  constructor(private campService: CampsService, private notify: NotificationsService) { }

  ngOnInit() {

    this.init();
  }

  init()
  {
    if (this.completeModel != null && typeof this.completeModel != "undefined")
      this.model = this.completeModel;
    else
    if (this.model == null || typeof this.model == "undefined")
    {
        this.model = {};
        this.model.genitore_sesso = "Maschio";
    }

  }


  get fiscalCodeValid() {
    if (this.model.genitore_codice_fiscale != null && typeof this.model.genitore_codice_fiscale != "undefined")
      return CodiceFiscale.check(this.model.genitore_codice_fiscale);
    return false;
  }

  /**
   * Verifica e trasforma il testo nel campo dedicato al codice fiscale
   */
  fiscalCodeOnChange() {
    if (this.model.genitore_codice_fiscale != null && typeof this.model.genitore_codice_fiscale != "undefined") {
      this.model.genitore_codice_fiscale = this.model.genitore_codice_fiscale.toUpperCase();
      if (CodiceFiscale.check(this.model.genitore_codice_fiscale)) {
        const cf = new CodiceFiscale(this.model.genitore_codice_fiscale)
        let fiscal_code = cf.toJSON();

        this.model.genitore_citta_nascita = fiscal_code.birthplace;
        if (fiscal_code.gender == 'M')
          this.model.genitore_sesso = "Maschio";
        else
          this.model.genitore_sesso = "Femmina";
        this.model.genitore_data_nascita = format(new Date(cf.year, cf.month - 1, cf.day), 'yyyy-MM-dd');

        let provincia = this.prov_array.find(x => x.sigla_automobilistica == fiscal_code.birthplaceProvincia);
        if (provincia)
          this.model.genitore_provincia_nascita = provincia.nome;

      }
    }
  }

  /**
   * Trasforma il testo nel campo dedicato alla mail e alla sua verifica
   */
  emailOnChange() {
    if (this.model.genitore_email != null && typeof this.model.genitore_email != "undefined") {
      this.model.genitore_email = this.model.genitore_email.toLowerCase();
    }
  }
  confirmEmailOnChange() {
    if (this.model.confirm_email != null && typeof this.model.confirm_email != "undefined") {
      this.model.confirm_email = this.model.confirm_email.toLowerCase();
    }
  }


  public get isFormValid() {
    return (this.form.form.valid && this.model.genitore_email == this.model.confirm_email);
  }

  submit() {

    if (this.isFormValid == false) {
      this.notify.error("Mancano alcuni dati per completare l'iscrizione");
      return;
    }

    this.nextPressed.emit( {type : 'parent', model: this.model} );
  }


  public get getCampsService() {
    return this.campService;
  }

}
