import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { CampsService } from '../_services/camps.service';

@Component({
  selector: 'app-camp-booking-form-hospitality',
  templateUrl: './camp-booking-form-hospitality.component.html',
  styleUrls: ['./camp-booking-form-hospitality.component.css']
})
export class CampBookingFormHospitalityComponent implements OnInit {

  public arrayTutor:any[] = [1, 2, 3, 4, 5];
  @ViewChild("form", { static: false }) form;
  
  @Input() currentSection: number;
  @Input() section: number = 0;
  @Input() camp: any;
  @Output() nextPressed  = new EventEmitter();
  @Output() backPressed  = new EventEmitter();
  
  @Input() completeModel: any;
  public model: any;

  constructor(private campService: CampsService, private notify: NotificationsService) { }

  ngOnInit() {

    this.init();
  }

  init()
  {
    if (this.completeModel != null && typeof this.completeModel != "undefined")
    {
      this.model = this.completeModel;
    }
    else
    if (this.model == null || typeof this.model == "undefined")
    {
        this.model = {};
        this.model.ospitare_trainee_tutor = "No";
    } 
  }

  public get isFormValid() {
    return this.form.form.valid;
  }

  submit() {

    if (this.isFormValid == false) {
      this.notify.error("Mancano alcuni dati per completare l'iscrizione");
      return;
    }

    //  costruisco le settimane
    this.buildHospitalityWeeks();

    if (this.model.ospitare_trainee_tutor == "Si" && this.model.settimane_ospitare_trainee_tutor.length <= 0) {
      this.notify.error("Non è stata selezionata nessuna settimana");
      return;
    }

    this.nextPressed.emit( {type: 'hospitality', model: this.model} );
  }

  indietro ()
  {
    this.backPressed.emit();
  }


  buildHospitalityWeeks ()
  {
    this.model.settimane_ospitare_trainee_tutor = "";

    if (this.model.ospitare_trainee_tutor == "Si")
    {
      if (this.model.ospitare_trainee_tutor_week_01 == true)
        this.model.settimane_ospitare_trainee_tutor += ",prima settimana"
      if (this.model.ospitare_trainee_tutor_week_02 == true)
        this.model.settimane_ospitare_trainee_tutor += ",seconda settimana"
      if (this.model.ospitare_trainee_tutor_week_03 == true)
        this.model.settimane_ospitare_trainee_tutor += ",terza settimana"
      if (this.model.ospitare_trainee_tutor_week_04 == true)
        this.model.settimane_ospitare_trainee_tutor += ",quarta settimana"

      if (this.model.settimane_ospitare_trainee_tutor.length > 0)
        this.model.settimane_ospitare_trainee_tutor = this.model.settimane_ospitare_trainee_tutor.substring(1);
    } else
    {
      delete this.model.ospitare_trainee_tutor_week_01;
      delete this.model.ospitare_trainee_tutor_week_02;
      delete this.model.ospitare_trainee_tutor_week_03;
      delete this.model.ospitare_trainee_tutor_week_04;
      delete this.model.quanti_ospitare_trainee_tutor;
    }
  }

  public get getCampsService() {
    return this.campService;
  }

}
