<form name="form" class="needs-validation" (ngSubmit)="submit()" #form="ngForm" novalidate>
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group">
        <label for="genitore_come_ci_conosceInput">Com'è venuto a conoscenza di questa iniziativa?</label>
        <select [(ngModel)]="model.genitore_come_ci_conosce" name=genitore_come_ci_conosce
          #genitore_come_ci_conosce="ngModel" class="form-control" id="genitore_come_ci_conosceInput">
          <option></option>
          <option [value]="'mailing'">Mailing</option>
          <option [value]="'Da un collega'">Da un collega</option>
          <option [value]="'Sito Web'">Sito Web</option>
          <option [value]="'Piattaforma Sofia'">Piattaforma Sofia</option>
          <option [value]="'Altro'">Altro</option>
        </select>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Vuoi ricevere informazioni sulle iniziative EDUCO?</label>
        <div class="form-check">
          <input [(ngModel)]="model.autorizzation_newsletter" name="autorizzation_newsletter"
            #autorizzation_newsletter="ngModel" class="form-check-input" type="checkbox"
            id="autorizzation_newsletterInput" (change)="autorizzationNewsletterOnChange($event)">
          <label class="form-check-label" for="autorizzation_newsletterInput">Autorizzo</label>
        </div>
      </div>
    </div>
  </div>
  
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Per quali attività?</label>
        <div class="form-check" *ngFor="let pref of newsletterPreference">
          <input [(ngModel)]="pref.preference" [name]="pref.name" class="form-check-input" type="checkbox"
            [value]="pref.preference" [id]="pref.name"
            (change)="autorizzationSingolNewsletterOnChange($event)">
          <label class="form-check-label" [for]="pref.name">{{pref.name}}</label>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="form-row">
    <div class="col-12">
      <p>Il/la sottoscritto/a dichiara di accettare quanto previsto dal GDPR 2016/679 - Informativa in materia
        di trattamento di dati personali.</p>
      <p><a target="_blank" href="https://www.educoitalia.it/privacy"><i class="fas fa-paperclip"></i>&nbsp;
          Scarica e visualizza l'informativa sulla tutela dei dati personali GDPR 2016/679</a></p>
    </div>
  </div>

  <div class="form-row">
    <div class="col-12 pl-5">
      <div class="form-group"
        [ngClass]="{ 'text-danger': (form.submitted || autorizzation.dirty || autorizzation.touched) && !autorizzation.valid && !autorizzation.disabled }">
        <div class="form-check">
          <input [(ngModel)]="model.autorizzation" name="autorizzation" #autorizzation="ngModel"
            class="form-check-input" type="checkbox" value="false" id="autorizzationInput" required>
          <label class="form-check-label" for="autorizzationInput">Autorizzo <span>*</span> </label>
        </div>
        <small class="form-text"
          *ngIf="(form.submitted || autorizzation.dirty || autorizzation.touched) && !autorizzation.valid && !autorizzation.disabled">L'autorizzazione
          è necessaria </small>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-12">
      <p>Il/la sottoscritto/a autorizza EDUCO a pubblicare e realizzare, ai sensi dell'art. 96 legge n.
        633/1941 (protezione del diritto d'autore e di altri diritti concessi al suo esercizio) servizi
        fotografici ai fini didattico-educativi e di promozione pubblicitaria delle attività svolte dalle
        suddette, attraverso 'utilizzazione dell'immagine.</p>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-12 pl-5">
      <div class="form-group">
        <div class="form-check">
          <input [(ngModel)]="model.autorizzation_image" name="autorizzation_image"
            #autorizzation_image="ngModel" class="form-check-input" type="checkbox" value='true'
            id="autorizzation_imageInput">
          <label class="form-check-label" for="autorizzation_imageInput">Autorizzo </label>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="section == currentSection">
      <hr>
      <div class="form-group row" *ngIf="section == currentSection">
        <div class="col-4">
          <button type="button" (click)="indietro()" class="btn btn-light btn-block"><i class="fas fa-arrow-left"></i>&nbsp; Indietro</button>
        </div>
        <div class="col-8">
          <button type="submit" class="btn btn-primary btn-block">Avanti</button>
        </div>
      </div>
    </div>
  
  </form>