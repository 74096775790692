import { Component, OnInit, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CampsService } from '../_services/camps.service';
import { Province } from '../_utils/province';
import { ActivatedRoute, Router } from '@angular/router';
import { format, parseISO, differenceInWeeks, addWeeks, isAfter, endOfWeek } from 'date-fns';
import { NotificationsService } from 'angular2-notifications';
import { AppStateService } from '../_services/appstate.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs/public_api';
import { CampBookingFormChildComponent } from '../camp-booking-form-child/camp-booking-form-child.component';

@Component({
  selector: 'app-camp-booking',
  templateUrl: './camp-booking.component.html',
  styleUrls: ['./camp-booking.component.css']
})
export class CampBookingComponent implements OnInit {

  @ViewChild("ricercaCamp", { static: false }) formRicerca;
  @ViewChild("description", { read: ElementRef, static: false }) descriptionBox: ElementRef;
  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  @ViewChildren('childSubscribers') childSubscribers !: QueryList<CampBookingFormChildComponent>;

  private sub: any;
  public prov_array = Province;

  public sectionNumber: number = 0;
  public maxSections: number = 5;

  private id: string = "";
  public currentCamp: any = {};

  private editSubscribers: boolean = false;
  public numOfSubscribers: number = 0;
  public max_num_of_subscriber: any[] = [1, 2, 3, 4, 5, 6, 7];
  public subscriberForms: any[] = [];

  public summaryMode: boolean = false;
  public completeModel: any = {};

  constructor(private route: ActivatedRoute, private campService: CampsService, private router: Router,
    private appState: AppStateService) { }

  ngOnInit() {

    /**
     * Aggiungo all'array una posizione per l'estero
     * Questa operazione andrà a lavorare sull'array originale e quindi tutte le volte che sarà richiamato avrà
     * al suo interno questi due oggetti aggiuntivi
    */
    this.prov_array.unshift(
      {
        id: null,
        id_regione: null,
        codice_citta_metropolitana: null,
        nome: "Estero",
        sigla_automobilistica: "estero",
        latitudine: 0,
        longitudine: 0
      },
      {
        id: null,
        id_regione: null,
        codice_citta_metropolitana: null,
        nome: "",
        sigla_automobilistica: "",
        latitudine: 0,
        longitudine: 0
      });

    this.sub = this.route.params.subscribe(params => {
      if (params['id'])
        this.id = '' + params['id'];

      if (this.id) {
        if (this.appState.filtered.length > 0)
          this.init();
        else {
          this.campService.getAllCampsAvailable(() => {
            this.init();
          })
        }
      }
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  campChangedEvent() {
    //  sono pronti i filtri allora creo la schermata
    this.init();
  }

  init() {

    if (this.id && this.appState.filtered) {
      let currentCampFind = this.appState.filtered.find(x => x.id == this.id);

      if (!currentCampFind) {
        this.router.navigate(['notfound'], { skipLocationChange: true });
        window.scrollTo(0, 0);
        return;
      }

      this.currentCamp = JSON.parse(JSON.stringify(currentCampFind));
      this.currentCamp.camp_weeks_num = this.calculateCampWeekNum();
      this.currentCamp.camp_weeks = this.calculateCampWeek();

      this.sectionNumber = 1;
    }

    //  TODO: @michele eliminare questa parte di test
    // this.completeModel = {
    //   parent_info:
    //   {
    //     genitore_sesso: "Maschio",
    //     genitore_grado_parentela: "padre",
    //     genitore_cognome: "Criscuolo",
    //     genitore_nome: "Michele",
    //     genitore_provincia_nascita: "Salerno",
    //     genitore_indirizzo: "Via San Leonardo 120, Traversa Migliaro",
    //     genitore_cap: 84131,
    //     genitore_codice_fiscale: "CRSMHL83E20H703J",
    //     genitore_citta_nascita: "SALERNO",
    //     genitore_data_nascita: "1983-05-20",
    //     genitore_citta: "salerno",
    //     genitore_provincia: "Salerno",
    //     genitore_cellulare: 3934360089,
    //     genitore_telefono_casa: null,
    //     genitore_email: "crisc.mic@gmail.com",
    //     confirm_email: "crisc.mic@gmail.com"
    //   },
    //   hospitality_info:
    //   {
    //     ospitare_trainee_tutor: "No",
    //     settimane_ospitare_trainee_tutor: ""
    //   },
    // children_info:
    // [
    //   {
    //     figlio_sesso: "Maschio",
    //     figlio_cognome: "Criscuolo",
    //     figlio_nome: "Lorenzo",
    //     figlio_codice_fiscale: "CRSMHL83E20H703J",
    //     figlio_citta_nascita: "SALERNO",
    //     figlio_data_nascita: "2017-12-17",
    //     figlio_provincia_nascita: "Salerno",
    //     figlio_indirizzo: "Via Relli 9",
    //     figlio_citta: "San Mango Piemonte",
    //     figlio_cap: 84090,
    //     figlio_provincia: "Salerno",
    //     figlio_cellulare: 3934360089,
    //     figlio_email: "crisc.mic@gmail.com",
    //     confirm_email: "crisc.mic@gmail.com",
    //     figlio_scuola_tipo: "Infanzia",
    //     figlio_scuola_classe: 1,
    //     figlio_note: "Bho!",
    //     figlio_numero_settimane: "prima settimana"
    //   },
    //   {
    //     figlio_sesso: "Maschio",
    //     figlio_cognome: "Criscuolo",
    //     figlio_nome: "Sophia",
    //     figlio_codice_fiscale: "CRSMHL83E20H703J",
    //     figlio_citta_nascita: "SALERNO",
    //     figlio_data_nascita: "2017-12-17",
    //     figlio_provincia_nascita: "Salerno",
    //     figlio_indirizzo: "Via Relli 9",
    //     figlio_citta: "San Mango Piemonte",
    //     figlio_cap: 84090,
    //     figlio_provincia: "Salerno",
    //     figlio_cellulare: 3934360089,
    //     figlio_email: "crisc.mic@gmail.com",
    //     confirm_email: "crisc.mic@gmail.com",
    //     figlio_scuola_tipo: "Infanzia",
    //     figlio_scuola_classe: 1,
    //     figlio_note: "Bho!",
    //     figlio_numero_settimane: "prima settimana"
    //   }
    // ],
    //   billing_info:
    //   {
    //     intestazione_fattura: "genitore",
    //     fattura_tipo: "persona_fisica",
    //     fattura_persona_fisica_cognome: "Criscuolo",
    //     fattura_persona_fisica_nome: "Criscuolo",
    //     fattura_persona_fisica_codice_fiscale: "CRSMHL83E20H703J",
    //     fattura_persona_fisica_indirizzo: "Via San Leonardo 120, Traversa Migliaro",
    //     fattura_persona_fisica_citta: "salerno",
    //     fattura_persona_fisica_cap: 84131,
    //     fattura_persona_fisica_provincia: "Salerno"
    //   },
    //   preference_info:
    //   {
    //     autorizzation_newsletter: true,
    //     genitore_come_ci_conosce: "Altro",
    //     autorizzation: true,
    //     autorizzation_image: true,
    //     consenso_newsletter: "Corsi di Formazione in Italia,Corsi di Formazione All’estero,Corsi di Formazione ,CLIL,Convegni,Teatrino,Educo Campus,Pop Music'n Culture,Storytelling,Educo Camps,Educo Summer Camps",
    //     genitore_consenso_dati_personali: "Si",
    //     genitore_consenso_immagine: "Si"
    //   }
    // };

  }

  calculateCampWeek() {
    var weeks = [];
    for (var i = 0; i < this.calculateCampWeekNum(); i++) {
      var weekStart = addWeeks(parseISO(this.currentCamp.start), i);
      var weekEnd = endOfWeek(weekStart);
      if (isAfter(weekEnd, parseISO(this.currentCamp.end)))
        weekEnd = parseISO(this.currentCamp.end);
      weeks.push({ start: weekStart, start_parsed: format(weekStart, 'dd/MM/yyyy'), end: weekEnd, end_parsed: format(weekEnd, 'dd/MM/yyyy') });
    }

    return weeks;
  }
  calculateCampWeekNum() {
    if (this.currentCamp.start != null && typeof this.currentCamp.start != "undefined" &&
      this.currentCamp.end != null && typeof this.currentCamp.end != "undefined") {
      return differenceInWeeks(parseISO(this.currentCamp.end), parseISO(this.currentCamp.start)) + 1;
    }

    return 0;
  }





  submit() {

    //  Dati del camp scelto
    this.completeModel.data_iscrizione = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.completeModel.camp_id = this.currentCamp.camp_id;
    this.completeModel.selectedBookingData = JSON.parse(JSON.stringify(this.currentCamp));

    //  salvo e vado alla pagina di conferma richiedendo prima l'ip ad un servizio esterno
    this.campService.saveSubscription(this.completeModel, () => {
      this.router.navigate(['prenota/' + this.id + '/confirmation']);
      window.scrollTo(0, 0);
    });

  }

  //  controlla che tutti gli iscritti siano stati registrati correttamente
  checkSubcribers() {
    let checkValid = true;
    this.childSubscribers.forEach(childForm => {
      childForm.forceSubmit();
      if (childForm.isFormValid == false)
        checkValid = false;
    });

    if (checkValid && checkValid == true) {
      let subscribersArray: any[] = [];
      this.childSubscribers.forEach(childForm => {
        subscribersArray.push(childForm.model);
      });
      this.continue({ type: 'children', model: subscribersArray });
    }
  }


  continue(event = null) {
    if (event != null && typeof event != "undefined") {
      switch (event.type) {
        case 'parent':
          this.completeModel.parent_info = event.model;
          break;
        case 'hospitality':
          this.completeModel.hospitality_info = event.model;
          break;
        case 'children':
          this.completeModel.children_info = event.model;
          break;
        case 'billing':
          this.completeModel.billing_info = event.model;
          break;
        case 'preferences':
          this.completeModel.preference_info = event.model;
          break;
      }

      // console.log(this.completeModel);
    }

    if (this.sectionNumber + 1 > this.maxSections) {
      this.summaryMode = true;
      return;
    }

    this.staticTabs.tabs.forEach(tab => {
      tab.disabled = true;
      tab.active = false;
    });
    this.staticTabs.tabs[this.sectionNumber].disabled = false;
    this.staticTabs.tabs[this.sectionNumber].active = true
    this.sectionNumber++;
  }

  backward() {
    if (this.sectionNumber < 1)
      return;

    this.sectionNumber--;
    this.staticTabs.tabs.forEach(tab => {
      tab.disabled = true;
      tab.active = false;
    });
    this.staticTabs.tabs[this.sectionNumber - 1].disabled = false;
    this.staticTabs.tabs[this.sectionNumber - 1].active = true;
  }

  returnToStart() {
    this.sectionNumber = 1;
    this.summaryMode = false;
  }

  //  E' possibile editare il numero di iscritti?
  get canEditSubscriber() {
    return this.editSubscribers || this.numOfSubscribers <= 0;
  }

  //  Action sul numero di iscritti
  editSubscriberNumber() {
    this.editSubscribers = true;
  }

  //  Cambia il numero di iscritti
  changeNumOfSubscriber() {
    let diff = this.numOfSubscribers - this.subscriberForms.length;

    if (diff > 0) {
      for (var i = 0; i < diff; i++) {
        this.subscriberForms.push({});
      }
    }
    else if (diff < 0) {
      for (var i = 0; i < Math.abs(diff); i++) {
        this.subscriberForms.pop();
      }
    }

    this.editSubscribers = false;
  }

  //  rimuove il form di un iscritto
  removeSubscriber(index) {
    this.subscriberForms.splice(index, 1);
    this.numOfSubscribers--;
  }

  progressBar() {
    return (this.sectionNumber / 5) * 100;
  }

  public get getCampsService() {
    return this.campService;
  }

}
