import { Component, OnInit } from '@angular/core';
import { CampsService } from '../_services/camps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-camp-booking-confirmation',
  templateUrl: './camp-booking-confirmation.component.html',
  styleUrls: ['./camp-booking-confirmation.component.css']
})
export class CampBookingConfirmationComponent implements OnInit {

  paymentURl;

  constructor(private campService: CampsService) { }

  ngOnInit() {

    if (!this.campService.currentSubscriber)
      this.campService.currentSubscriber = {};
    else
      this.init();
  }



  init() {

    if (typeof this.campService.currentSubscriber.payment_request != "undefined" && this.campService.currentSubscriber.payment_request != null)
    {
      this.paymentURl = environment.paymentBasePath+""+this.campService.currentSubscriber.payment_request.id;
      this.campService.currentSubscriber.payment_request.item_amount_formatted = this.campService.currentSubscriber.payment_request.item_amount / 100;
    }
  }


  public get getCampsService() {
    return this.campService;
  }

}
