<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-custom fixed-top">
  <div class="container">
    <a class="navbar-brand" href="#"><img src="assets/img/logo.png"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="https://www.educoitalia.it/">Educo Italia</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://www.educoitalia.it/educo-camp">Educo English Camp</a>
          </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.educoitalia.it/contatti">Contatti</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<simple-notifications [options]="notificationOptions"></simple-notifications>
