<header class="masthead text-center text-white green">
  <div class="masthead-content">
    <div class="container">
      <h1 class="masthead-heading mb-0"><i class="far fa-thumbs-up"></i><br>Iscrizione confermata</h1>
      <div class="row p-2 mt-5"
        *ngIf="getCampsService.currentSubscriber && getCampsService.currentSubscriber.selectedBookingData && getCampsService.currentSubscriber.selectedBookingData.quote_enrollment_fee <= 0">
        <div class="col-md-12">
          <h1 class="text-center"><i class="far fa-next"></i> Prossimo passo</h1>
          <div class="card bg-success text-white m-3">
            <div class="card-body p-3">
              <h4 class="card-text text-center">
                Grazie per la tua iscrizione! Per qualsiasi chiarimento o dubbio non esitare a
                scriverci alla nostra email <a href="mailto:educocamp@educoitalia.it">educocamp@educoitalia.it</a>
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row p-2 mt-5"
        *ngIf="getCampsService.currentSubscriber && getCampsService.currentSubscriber.selectedBookingData && getCampsService.currentSubscriber.selectedBookingData.quote_enrollment_fee > 0">
        <div class="col-md-12">
          <h1 class="text-center"><i class="far fa-next"></i> Prossimo passo</h1>
          <div class="card bg-success text-white m-3">
            <div class="card-body p-3">
              <h5 class="card-text text-center text-white">
                Grazie per la tua iscrizione! Per confermare e completare l'iscrizione bisogna
                effettuare il pagamento della quota di iscrizione.<br><br>
                Il pagamento può avvenire con bonifico bancario o pagamento online.<br><br>
                Non appena hai la ricevuta del pagamento inviaci un pdf o jpeg all'email <a
                  href="mailto:educocamp@educoitalia.it">educocamp@educoitalia.it</a><br>
              </h5>
              <hr>
              <p class="mb-2 mt-2" *ngIf="getCampsService?.currentSubscriber?.payment_request && paymentURl">
                <a class="btn btn-block btn-primary btn-lg mb-4 mt-4" [href]="paymentURl">Paga Ora la quota di
                  iscrizione di <b>{{getCampsService.currentSubscriber?.payment_request?.item_amount_formatted | currency:'EUR':true}}</b></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-circle-3 bg-circle"></div>
  <div class="bg-circle-2 bg-circle"></div>
  <div class="bg-circle-1 bg-circle"></div>
  <div class="bg-circle-4 bg-circle"></div>
</header>


<div class="container">

  <!-- LODING -->
  <div class="row" *ngIf="getCampsService.isOngoingRequest">
    <div class="col-md-12 text-center">
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <div class="card p-1 mb-2 mt-5 mt-2"
    *ngIf="getCampsService.currentSubscriber && getCampsService.currentSubscriber.selectedBookingData">
    <div class="card-body">
      <h1 class="text-center">Iscrizione</h1>
      <div class="row justify-content-md-center m-3"
        *ngIf="getCampsService.currentSubscriber?.payment_request?.item_amount_formatted">
        <div class="col-md-12 text-center">
          Quota Iscrizione<br>
          <b>{{getCampsService.currentSubscriber?.payment_request?.item_amount_formatted | currency:'EUR':true}}</b>
        </div>
      </div>
      <h5 class="card-title text-uppercase">{{getCampsService.currentSubscriber.selectedBookingData.venue_name}}</h5>
      <p class="card-text">
        <i class="far fa-calendar-alt"></i> dal {{getCampsService.currentSubscriber.selectedBookingData.start | date}}
        al {{getCampsService.currentSubscriber.selectedBookingData.end | date}}
      </p>
      <p class="card-text">
        <i class="fas fa-map-marked-alt"></i> {{getCampsService.currentSubscriber.selectedBookingData.route}},
        {{getCampsService.currentSubscriber.selectedBookingData.street_number}}<br>
        <small>{{getCampsService.currentSubscriber.selectedBookingData.locality}}
          ({{getCampsService.currentSubscriber.selectedBookingData.administrative_area_level_2_short_version}},
          {{getCampsService.currentSubscriber.selectedBookingData.administrative_area_level_1_long_version}})</small><br>
        <small>{{getCampsService.currentSubscriber.selectedBookingData.postal_code}}
          {{getCampsService.currentSubscriber.selectedBookingData.country_long_version}}</small>
      </p>
      <p class="card-text">
        <i class="fas fa-graduation-cap"></i>&nbsp;<span
          *ngFor="let a of getCampsService.currentSubscriber.selectedBookingData.audience_name; let last = last">{{a}}<span
            *ngIf="!last">,&nbsp;</span></span>
      </p>
      <p class="card-text">
        <i class="fas fa-fingerprint"></i>&nbsp;{{getCampsService.currentSubscriber.selectedBookingData.camp_code}}
      </p>

      <hr>
      <app-camp-booking-form-summary [model]="getCampsService?.currentSubscriber" [enableAction]="false">
      </app-camp-booking-form-summary>
    </div>
  </div>

</div>
