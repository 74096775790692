import { Component, OnInit, Output } from '@angular/core';
import { AppStateService } from '../_services/appstate.service';
import { CampsService } from '../_services/camps.service';
import { getMonth, parseISO } from 'date-fns';
import { Regioni } from '../_utils/regioni';
import { Province } from '../_utils/province';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-camp-search-filters',
  templateUrl: './camp-search-filters.component.html',
  styleUrls: ['./camp-search-filters.component.css']
})
export class CampSearchFiltersComponent implements OnInit {

  @Output() campChanged = new EventEmitter();

  constructor(private appState: AppStateService, private campService: CampsService) { }

  objectKeys = Object.keys;

  hideForm: boolean = false;
  regions: any[] = [];
  province: any[] = [];
  months: any[] = [
    { value: 0, name: 'Gennaio', text: 'Gennaio' }, { value: 1, name: 'Febbraio', text: 'Febbraio' }, { value: 2, name: 'Marzo', text: 'Marzo' },
    { value: 3, name: 'Aprile', text: 'Aprile' }, { value: 4, name: 'Maggio', text: 'Maggio' }, { value: 5, name: 'Giugno', text: 'Giugno' },
    { value: 6, name: 'Luglio', text: 'Luglio' }, { value: 7, name: 'Agosto', text: 'Agosto' }, { value: 8, name: 'Settembre', text: 'Settembre' },
    { value: 9, name: 'Ottobre', text: 'Ottobre' }, { value: 10, name: 'Novembre', text: 'Novembre' }, { value: 11, name: 'Dicembre', text: 'Dicembre' }
  ];

  originalCamps: any[] = [];
  regionsAndProvince: any[] = [];

  campTypes: any[] = [
    {
      type: '1',
      name: 'Educo Camp',
      text: 'Educo Camp'
    },
    {
      type: '2',
      name: 'Educo Summer Camps',
      text: 'Educo Summer Camps'
    },
    {
      type: '3',
      name: 'International Camps',
      text: 'International Camps'
    },
    {
      type: '4',
      name: 'Educo Campus',
      text: 'Educo Campus'
    },
    {
      type: '5',
      name: 'Little Star Camps',
      text: 'Little Star Camps'
    }
  ];

  audience_name_available: any = {};

  ngOnInit() {

    this.campService.getAllCampsAvailable((camps) => {

      //  raggruppo i risultati in modo da poter creare i filtri
      this.initFilters(JSON.parse(JSON.stringify(camps)));

      //  calcolo sempre i filtri
      // this.changeFilters();
    });
  }


  public show() {
    this.hideForm = false;
  }

  public hide() {
    this.hideForm = true;
  }

  //  reset dei filtri impostati
  resetFilter() {
    this.appState.searchModel = {};
    this.setMonthCorses();
    this.setRegionProvince();
    this.appState.filtered = [];
    this.campChanged.emit('update');
  }


  initFilters(camps: any) {
    this.appState.nextDays = [];

    this.buildRegione();
    this.buildProvince();

    this.audience_name_available = this.campService.audience_name_available;

    this.originalCamps = this.campService.allCamps;

    //  rimuovo i duplicati se ce ne sono
    this.campTypes = [...new Set(this.campTypes)];
    this.campTypes.sort();

    //  setto il numero di corsi per regione/provincia
    this.setRegionProvince();

    //  setto il numero di corsi per ogni data presente nell'array
    this.setMonthCorses();
  }


  //  Cambio dei filtri sulla scheda
  changeFilters() {

    var camps = JSON.parse(JSON.stringify(this.originalCamps));
    this.appState.filtered = camps;

    // filtro in base al camp code, non lavora su filtered perché è esclusivo insieme al filtro dell'audience
    if (this.appState.searchModel.campCode && this.appState.searchModel.campCode.length > 0) {
      this.appState.filtered = camps.filter((camp) => {
        return camp.camp_code.toLowerCase() == this.appState.searchModel.campCode.trim().toLowerCase();
      });
    }

    //  filtro il tipo di audience, non lavora su filtered per lo stesso motivo del filtro sopra sul camp code
    if (this.appState.searchModel.audience && this.appState.searchModel.audience.key) {
      this.appState.filtered = camps.filter((camp) => {
        let audienceModel = JSON.parse(camp.audience);
        for (let a in audienceModel) {
          if (audienceModel[a]) {
            if (a == this.appState.searchModel.audience.key)
              return true;
          }
        }
        return false;
      });
    }

    //  filtro il nome dei corsi
    // if (this.appState.searchModel.campType)
    //   if (this.appState.searchModel.campType.length > 0)
    //     this.appState.filtered = camps.filter(x => x.title == this.appState.searchModel.campType)

    //  filtro la regione dei corsi
    if (this.appState.searchModel.regionprovince) {
      if (this.appState.searchModel.regionprovince.length > 2) {
        var check = false;
        var checked: any[] = [];

        if (this.appState.searchModel.regionprovince == "Estero") {
          for (var i = 0; i < this.appState.filtered.length; i++) {
            check = false;
            let paese = this.appState.filtered[i].country_long_version;
            if (this.appState.filtered[i].country_long_version)
              paese = this.appState.filtered[i].country_long_version.trim().toLowerCase();
            if (paese != 'italy' && paese != 'italia')
              check = true;
            if (check == true) {
              checked.push(this.appState.filtered[i]);
            }
          }
        }
        else {
          for (var i = 0; i < this.appState.filtered.length; i++) {
            check = false;
            if (this.appState.filtered[i].administrative_area_level_1_long_version == this.appState.searchModel.regionprovince)
              check = true;
            if (check == true) {
              checked.push(this.appState.filtered[i]);
            }
          }
        }

        this.appState.filtered = JSON.parse(JSON.stringify(checked));
      }

    }

    //  filtro la provincia dei corsi
    if (this.appState.searchModel.regionprovince) {
      if (this.appState.searchModel.regionprovince.length > 0 && this.appState.searchModel.regionprovince.length <= 2) {
        var check = false;
        var checked: any[] = [];

        for (var i = 0; i < this.appState.filtered.length; i++) {
          check = false;
          if (this.appState.filtered[i].administrative_area_level_2_short_version == this.appState.searchModel.regionprovince)
            check = true;
          if (check == true) {
            checked.push(this.appState.filtered[i]);
          }

        }

        this.appState.filtered = JSON.parse(JSON.stringify(checked));
      }
    }

    //  filtro le date dei corsi
    if (this.appState.searchModel.month)
      if (this.appState.searchModel.month.length > 0) {
        var check = false;
        var checked: any[] = [];

        for (var i = 0; i < this.appState.filtered.length; i++) {
          check = false;
          for (var j = 0; j < this.appState.filtered[i].camp_schedule.length; j++) {
            if (getMonth(parseISO(this.appState.filtered[i].camp_schedule[j].start)) == this.appState.searchModel.month)
              check = true;
          }
          if (check == true) {
            checked.push(this.appState.filtered[i]);
            checked[checked.length - 1].camp_schedule = checked[checked.length - 1].camp_schedule.filter(x => getMonth(parseISO(x.start)) == this.appState.searchModel.month);
          }

        }

        this.appState.filtered = JSON.parse(JSON.stringify(checked));
      }

    if ((this.appState.searchModel.regionprovince && this.appState.searchModel.regionprovince.length > 0) || (this.appState.searchModel.month && this.appState.searchModel.month.length > 0)) {
      this.appState.showDetails = true;
    }
    else {
      this.appState.showDetails = false;
    }

    this.campChanged.emit('update');
  }


  //  costruisco l'array delle regioni
  buildRegione() {
    this.regions.push({ id: null, value: 'Estero', name: 'Mostra tutti i corsi all\'estero', text: 'Mostra tutti i corsi all\'estero', type: 'region' });
    Regioni.forEach(regione => {
      this.regions.push({ id: regione.id, value: regione.nome, name: regione.nome, text: regione.nome, type: 'region' });
    });
    this.regions.sort();
  }


  //  costruisco l'array delle province
  buildProvince() {
    Province.forEach(provincia => {
      this.province.push({ id: provincia.id, region_id: provincia.id_regione, name: provincia.nome, value: provincia.sigla_automobilistica, text: provincia.nome, type: 'province' });
    });
    this.province.sort();
  }




  //  setta il numero di corsi per mese
  setMonthCorses() {
    var months = [];
    this.appState.filtered.forEach(camp => {
      if (camp.start)
        months.push(getMonth(parseISO(camp.start)));
    });

    //  setto il numero di corsi per ogni data presente nell'array
    for (var i = 0; i < this.months.length; i++) {
      var counter = months.filter(x => x == i).length;
      this.months[i].text = this.months[i].name + ' (' + counter + ' Camp)';
      if (counter > 0)
        this.months[i].text += ' •';
      // this.months[i].text = this.months[i].name + ' (' + months.filter(x => x == i).length + ' Camp)';
    }

  }


  //  setta il numero di corsi per regione
  setRegion() {
    var regions: string[] = [];

    this.appState.filtered.forEach(camp => {
      if (camp.administrative_area_level_1_long_version)
        regions.push(camp.administrative_area_level_1_long_version);
    });

    //  setto il numero di corsi per ogni regione presente nell'array
    for (var i = 0; i < this.regions.length; i++) {
      var reg = this.regions[i].value.substring(0, 4);
      var counter = regions.filter(x => x.includes(reg)).length;
      // this.regions[i].text = this.regions[i].name + ' (' + counter + ' Camp)';
      this.regions[i].text = this.regions[i].name;
      this.regions[i].province = this.province.filter(x => x.region_id == this.regions[i].id)
      if (counter > 0)
        this.regions[i].text += ' •';
      // this.regions[i].text = this.regions[i].name + ' (' + regions.filter(x => x.includes(reg)).length + ' Camp)';
    }

    //  setto tutti i paesi diversi dall'italia alla fine dell'array
    var counter = 0;
    this.appState.filtered.forEach(camp => {
      if (camp.country_long_version) {
        let paese = camp.country_long_version.trim().toLowerCase();
        if (paese != 'italy' && paese != 'italia') {
          counter++;
        }
      }
    });
    // this.regions[0].text = this.regions[0].name + ' (' + counter + ' Camp)';
    this.regions[0].text = this.regions[0].name;
  }

  //  set il numero di corsi per provincia
  setProvince() {
    var province: string[] = [];

    this.appState.filtered.forEach(camp => {
      if (camp.administrative_area_level_2_short_version)
        province.push(camp.administrative_area_level_2_short_version)
    });

    //  setto il numero di corsi per ogni provincia presente nell'array
    for (var i = 0; i < this.province.length; i++) {
      var reg = this.province[i].value.substring(0, 4);
      var counter = province.filter(x => x.includes(reg)).length;
      // this.province[i].text = this.province[i].name + ' (' + counter + ' Camp)';
      this.province[i].text = this.province[i].name;
      if (counter > 0)
        this.province[i].text += ' •';
      // this.province[i].text = this.province[i].name + ' (' + province.filter(x => x.includes(reg)).length + ' Camp)';
    }
  }

  //  setta il numero di corsi per regione/provincia
  setRegionProvince() {

    this.setRegion();
    this.setProvince();

    //  preparo l'array che conterrà sia le regioni che le province
    this.regionsAndProvince = [];
    this.regions.forEach(region => {
      this.regionsAndProvince.push(region);
      region.province.forEach(province => {
        this.regionsAndProvince.push(province);
      });
    });

  }

  filterByAudience(audience_selected) {

    this.appState.searchModel["audience"] = this.audience_name_available[audience_selected];
    this.appState.searchModel.campCode = null;

    this.changeFilters();

    this.campChanged.emit('update');
  }

  filterByCampCode() {

    this.changeFilters();

    this.campChanged.emit('update');
  }


  public get getCampsService() {
    return this.campService;
  }

  public get getAppState() {
    return this.appState;
  }
}
