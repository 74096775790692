import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CampsService } from '../_services/camps.service';

@Component({
  selector: 'app-camp-booking-form-summary',
  templateUrl: './camp-booking-form-summary.component.html',
  styleUrls: ['./camp-booking-form-summary.component.css']
})
export class CampBookingFormSummaryComponent implements OnInit {

  @Input() model : any = {};
  @Input() enableAction: boolean = true;
  @Output() backPressed = new EventEmitter();
  @Output() submitPressed = new EventEmitter();
  
  constructor(private campService: CampsService) { }

  ngOnInit() {
  }

  indietro ()
  {
    this.backPressed.emit();
  }

  iscrivi()
  {
    this.submitPressed.emit();
  }

  get CampService ()
  {
    return this.campService;
  }
}
